import { Link } from "react-router-dom";
import TablePickerView from "../components/TablePickerView";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { Button, Input, notification, Result, Space, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { deployCampaign } from "../hooks/useAgentStore";

const CampaignsPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const [campaign, setCampaign] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { apiKey, myProfile } = useAuth();
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleCampaignChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, "_");
    setCampaign(value);
  };

  const handleSubmit = async () => {
    if (!apiKey) return;
    if (!campaign.trim()) {
      notification.error({
        message: "Error",
        description: "Please enter a campaign name",
      });
      return;
    }

    setIsSubmitting(true);

    const results = await deployCampaign({
      token: apiKey,
      payload: {
        campaign,
      },
    });
    console.log(results);
    setResponseMessage(
      `Deployed campaign "${campaign}" with ${results.count} tweets scheduled`
    );
    setCampaign("");
    setIsSubmitting(false);
  };

  if (!isLoaded || !myProfile) {
    return null; // or a loading indicator
  }
  return (
    <>
      <br />
      <br />
      <br />
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Deploy" key="1">
          <div style={{ maxWidth: "600px", margin: "0 auto", padding: "20px" }}>
            <h2>Deploy Your Campaign</h2>
            <p>
              Enter the name of your campaign and click deploy to publish live
            </p>
            <Space.Compact style={{ width: "100%" }}>
              <Input
                placeholder="Enter campaign name"
                value={campaign}
                onChange={handleCampaignChange}
              />
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={!campaign.trim()}
              >
                Deploy Campaign
              </Button>
            </Space.Compact>
            <br />
            <br />
            {responseMessage && (
              <Result
                status="success"
                title="Successfully Deployed Campaign"
                subTitle={`${responseMessage}. You will receive a Telegram notification when the campaign is live.`}
                extra={[
                  <a
                    href={myProfile.nocodb_host_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button type="primary" key="console">
                      Open Database
                    </Button>
                  </a>,
                ]}
              />
            )}
            <br />
            <br />
            <br />
            <br />
          </div>
        </TabPane>
        <TabPane tab="Campaigns" key="2">
          <TablePickerView
            title="Campaigns"
            cookieSlug={`nocodb_campaigns_view_${myProfile.telegram_username}`}
            description={
              <div style={{ lineHeight: "1.3rem" }}>
                <span>View your tweets before & after publish.</span>
                <br />
                <span>
                  Telegram{" "}
                  <a href="https://t.me/TweetMob_bot" target="_blank">
                    @TweetMob_bot
                  </a>
                  {` `}
                  <code style={{ backgroundColor: "#e1e4ff" }}>{`/tweet`}</code>
                  {` or `}
                  <code style={{ backgroundColor: "#e1e4ff" }}>{`/reply`}</code>
                  {" to add posts."}
                </span>
                <br />
                <span>
                  Or use the <Link to="/write">bulk writer</Link>
                </span>
              </div>
            }
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default CampaignsPage;
