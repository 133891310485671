import React, { useState } from "react";
import {
  Button,
  Drawer,
  Input,
  List,
  Space,
  Affix,
  Select,
  Avatar,
  message,
  Tag,
} from "antd";
import { TwitterOutlined, LinkOutlined } from "@ant-design/icons";
import { tweetNow, useAgentStore } from "../hooks/useAgentStore";
import { useShallow } from "zustand/shallow";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../hooks/useAuth";
import { saveTweetsToCampaign } from "../hooks/useTweetStore";
import { v4 as uuidv4 } from "uuid";

const { TextArea } = Input;
const { Option } = Select;

export interface Agent {
  agent_uuid: string;
  avatar_url: string;
  name: string;
  internal_notes: string;
  twitter_url: string;
  twitter_username: string;
  teams: string[];
  status: string;
}

interface TweetHistoricalRecord {
  id: number;
  content: string;
  replyTo?: string;
  agent: string;
  campaign?: string;
  proofUrl?: string;
  thumbnail?: string;
}

const QuickTweet: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [tweetContent, setTweetContent] = useState("");
  const [replyTo, setReplyTo] = useState("");
  const [campaign, setCampaign] = useState("");
  const [loading, setLoading] = useState(false);
  const [tweets, setTweets] = useState<TweetHistoricalRecord[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const { apiKey } = useAuth();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { agents } = useAgentStore(
    useShallow((state) => ({
      agents: state.agents,
    }))
  );

  const handleTweet = async () => {
    if (!selectedAgent || !apiKey) return;
    setLoading(true);
    const tweeted = await tweetNow({
      token: apiKey,
      payload: {
        tweet_text: tweetContent,
        reply_to_url: replyTo,
        agent_username: selectedAgent.twitter_username,
        campaign: campaign,
        agent_uuid: selectedAgent.agent_uuid,
      },
    });

    const newTweet: TweetHistoricalRecord = {
      id: Date.now(),
      content: tweeted.tweet_text,
      replyTo: tweeted.reply_to_url,
      agent: tweeted.agent,
      campaign: tweeted.campaign,
      proofUrl: tweeted.proof_url,
      thumbnail: tweeted.thumbnail,
    };

    if (!tweeted.proof_url) {
      message.warning("Try again later");
      newTweet.content =
        "Your account is not healthy enough. Scroll on mobile & try again later. Your tweet was saved to campaign database.";
      newTweet.agent = "🟡 Error Tweeting";
    }

    setTweets([newTweet, ...tweets]);
    setTweetContent("");
    setReplyTo("");
    setCampaign("");
    setLoading(false);

    if (!tweeted.proof_url) {
      await saveTweetsToCampaign({
        token: apiKey,
        payload: {
          tweets: [
            {
              entryID: uuidv4(),
              tweet_text: tweetContent,
              reply_to_url: replyTo,
              campaign,
              internal_notes: "",
              agent: selectedAgent.twitter_username,
            },
          ],
        },
      });
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success("URL copied to clipboard");
      },
      () => {
        message.error("Failed to copy URL");
      }
    );
  };

  const renderAgentStatusTag = (status: string) => {
    let color = "default";
    switch (status) {
      case "requested":
        color = "blue";
        break;
      case "warmup":
        color = "orange";
        break;
      case "healthy":
        color = "green";
        break;
      case "warning":
        color = "yellow";
        break;
      case "error":
        color = "red";
        break;
      case "asleep":
        color = "gray";
        break;
      default:
        color = "default";
    }
    return <Tag color={color}>{status}</Tag>;
  };

  const agentByUsername = (username: string) => {
    return agents.find((a) => a.twitter_username === username);
  };

  return (
    <>
      <Affix style={{ position: "fixed", top: 20, right: 20 }}>
        <Button
          type="primary"
          size={isMobile ? "middle" : "large"}
          icon={<TwitterOutlined />}
          onClick={() => setDrawerVisible(true)}
        >
          Tweet
        </Button>
      </Affix>
      <Drawer
        title="Quick Tweet"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={isMobile ? "100%" : 450}
      >
        <Space direction="vertical" style={{ width: "100%" }} size="large">
          <Select
            style={{ width: "100%" }}
            placeholder="Write as Agent"
            onChange={(value) =>
              setSelectedAgent(
                agents.find((a) => a.agent_uuid === value) || null
              )
            }
          >
            {agents
              .filter((a) => a.agent_uuid !== "random")
              .map((agent) => (
                <Option
                  disabled={
                    agent.status !== "healthy" && agent.status !== "warning"
                  }
                  key={agent.agent_uuid}
                  value={agent.agent_uuid}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Space>
                      <Avatar size="small" src={agent.avatar_url} />
                      {agent.name}
                    </Space>
                    {renderAgentStatusTag(agent.status)}
                  </div>
                </Option>
              ))}
          </Select>
          <div>
            <Input
              placeholder="Reply to URL (optional)"
              value={replyTo}
              onChange={(e) => setReplyTo(e.target.value)}
            />
            <TextArea
              rows={4}
              placeholder="Write Your Tweet"
              value={tweetContent}
              onChange={(e) => setTweetContent(e.target.value)}
              style={{ margin: "0px 0px 5px 0px" }}
            />
            <Input
              placeholder="Campaign (optional)"
              value={campaign}
              onChange={(e) =>
                setCampaign(e.target.value.replace(" ", "_").toLowerCase())
              }
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="primary"
              icon={<TwitterOutlined />}
              loading={loading}
              onClick={handleTweet}
              disabled={!tweetContent.trim() || !selectedAgent}
            >
              Tweet Now
            </Button>
            <Button
              loading={loading}
              disabled={!tweetContent.trim() || !selectedAgent}
              style={{ marginLeft: 5 }}
            >
              Save Campaign
            </Button>
          </div>
        </Space>
        <List
          header={<div>Recent Tweets</div>}
          dataSource={tweets}
          renderItem={(item) => (
            <List.Item
              extra={
                <Button
                  type="text"
                  icon={
                    <LinkOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
                  }
                  size="small"
                  onClick={() => {
                    copyToClipboard(item.proofUrl || "");
                  }}
                />
              }
            >
              <List.Item.Meta
                avatar={<Avatar src={item.thumbnail} />}
                title={`${agentByUsername(item.agent)?.name}`}
                description={
                  <>
                    <div>{item.content}</div>
                  </>
                }
              />
            </List.Item>
          )}
          style={{ marginTop: "20px" }}
        />
      </Drawer>
    </>
  );
};

export default QuickTweet;
