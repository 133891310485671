import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import {
  Input,
  Button,
  Select,
  Popover,
  Row,
  Col,
  Space,
  Tooltip,
  Dropdown,
  message,
  Avatar,
} from "antd";
import { useShallow } from "zustand/react/shallow";
import {
  MessageOutlined,
  LeftOutlined,
  RightOutlined,
  LinkOutlined,
  UserOutlined,
  FormOutlined,
  UserSwitchOutlined,
  MoreOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { TweetRow, useStackSpreadsheets } from "../hooks/useStackSpreadsheets";
import { useMediaQuery } from "react-responsive";
import {
  generateBlankTweetsWebook,
  useTweetStore,
} from "../hooks/useTweetStore";
import { remove, uniq } from "lodash";
import { useAuth } from "../hooks/useAuth";
import {
  Agent,
  getRandomFromAgentsSet,
  useAgentStore,
} from "../hooks/useAgentStore";

const { TextArea } = Input;

interface AdvancedSpreadsheetEditorProps {
  replyToUrl: string;
  campaign: string;
  generateButtonID: string;
  saveButtonID: string;
  tweetText: string;
}

interface ReplyRowProps {
  row: TweetRow;
  updateRow: (id: string, field: keyof TweetRow, value: any) => void;
  removeRow: (id: string) => void;
  isLastRow: boolean;
  onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  showQuote: boolean;
  campaign: string;
  replyToUrl: string;
  tweetText: string;
  generateButtonID: string;
  saveButtonID: string;
}

const QuotePreview: React.FC<{
  username: string;
  text: string;
  url: string;
}> = ({ username, text, url }) => (
  <div
    style={{
      border: "1px solid #d9d9d9",
      borderRadius: "4px",
      padding: "8px",
      marginTop: "8px",
      background: "#f5f5f5",

      maxHeight: "100px",
      overflowY: "auto",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "4px",
      }}
    >
      <Space>
        <UserOutlined />
        <span>{username}</span>
      </Space>
      <LinkOutlined
        onClick={() => {
          navigator.clipboard.writeText(url);
          message.info(`Copied Link`);
        }}
      />
    </div>
    <div>{text}</div>
  </div>
);

const ReplyRow = React.forwardRef<HTMLTextAreaElement, ReplyRowProps>(
  (
    {
      row,
      updateRow,
      removeRow,
      isLastRow,
      onKeyDown,
      campaign,
      tweetText,
      replyToUrl,
      generateButtonID,
      saveButtonID,
    },
    ref
  ) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [optionsMap, setOptionsMap] = useState<Record<string, string>>({});
    const { apiKey } = useAuth();
    const {
      addTweetToQueue,
      removeTweetFromQueue,
      prompt,
      quoteSettings,
      mentionSettings,
      agentSettings,
    } = useTweetStore(
      useShallow((state) => ({
        addTweetToQueue: state.addTweetToQueue,
        removeTweetFromQueue: state.removeTweetFromQueue,
        prompt: state.prompt,
        quoteSettings: state.quoteSettings,
        mentionSettings: state.mentionSettings,
        agentSettings: state.agentSettings,
      }))
    );
    const { agents } = useAgentStore(
      useShallow((state) => ({
        agents: state.agents,
      }))
    );

    const [forceUpdateKey, setForceUpdateKey] = useState(0);
    const [selectedAgent, setSelectedAgent] = useState<Agent>();

    useEffect(() => {
      const agent = getRandomFromAgentsSet(
        agents,
        agentSettings.selectedAgent.agent_uuid
      );
      setSelectedAgent(agent);
    }, []);

    const handlePopoverVisibleChange = (visible: boolean) => {
      if (visible) {
        setForceUpdateKey((prev) => prev + 1);
      }
    };

    const handleInternalNotesChange = (
      e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      updateRow(row.id, "internal_notes", e.target.value);
    };

    const agentOptions = useMemo(() => {
      const options = [
        {
          agent_uuid: "random_public",
          name: "Random Public",
          avatar_url:
            "https://play-lh.googleusercontent.com/jKPgqX4JBR97fgfOYcxtPLHwNhnfdwkxOoq66cFNfc0jTqZg_ZAxKsGxSsYfgyWobAUs=w240-h480-rw",
        },
      ];

      agents.forEach((agent) => {
        options.push({
          agent_uuid: agent.agent_uuid,
          name: agent.name,
          avatar_url: agent.avatar_url,
        });
      });

      return options;
    }, [agents]);

    const handleAgentChange = (value: string) => {
      const selectedOption = agentOptions.find(
        (option) => option.agent_uuid === value
      );
      if (selectedOption) {
        if (selectedOption.agent_uuid === "random_public") {
          // For public, team, or team-specific options, we create a pseudo-Agent
          setSelectedAgent({
            agent_uuid: selectedOption.agent_uuid,
            avatar_url: selectedOption.avatar_url || "",
            name: selectedOption.name,
            internal_notes: "",
            twitter_url: "",
            twitter_username: "",
            teams: [],
            status: "active",
          });
        } else {
          // For individual agents, we can directly set the Agent object
          setSelectedAgent(selectedOption as Agent);
        }
        updateRow(row.id, "agent_uuid", selectedOption.agent_uuid);
      }
    };

    const filterOption = (
      input: string,
      option?: { children: string; value: string }
    ) => {
      return (
        (option?.children as string)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };

    const saveTweet = () => {
      if (row.tweet_text.trim() !== "") {
        updateRow(row.id, "locked", true);
        console.log(`row`, row);
        addTweetToQueue({
          id: row.id,
          tweet_text: row.tweet_text,
          internal_notes: row.internal_notes,
          campaign,
          reply_to_url: replyToUrl,
          agent_uuid: selectedAgent?.agent_uuid || "random_public",
          agent_name: selectedAgent?.name || "Random Public",
        });
      }
    };

    const editTweetRecall = () => {
      updateRow(row.id, "locked", false);
      removeTweetFromQueue(row.id);
    };

    const removeTweet = () => {
      removeRow(row.id);
      removeTweetFromQueue(row.id);
    };

    const generateTweet = async (row: TweetRow) => {
      if (row.locked) return;
      updateRow(row.id, "loading", true);
      updateRow(row.id, "locked", true);
      updateRow(row.id, "tweet_text", "Generating...");
      if (apiKey) {
        console.log(`quoteSettings.tags`, quoteSettings.tags);
        const generatedTweets = await generateBlankTweetsWebook({
          token: apiKey,
          prompt,
          campaign,
          tweets: [
            {
              entryID: row.id,
              should_quote: quoteSettings.checked,
              quote_tags: quoteSettings.tags.join(","),
              original_tweet_text: tweetText,
              should_mention: mentionSettings.checked,
              mention_tags: mentionSettings.tags.join(","),
            },
          ],
        });
        generatedTweets.forEach((t) => {
          const firstOption = t[0];
          setOptionsMap((prev) => ({
            ...prev,
            [firstOption.entryID]: firstOption.optionID,
          }));
          console.log(`Generated Tweet:`, t);
          updateRow(firstOption.entryID, "loading", false);
          updateRow(firstOption.entryID, "locked", false);
          updateRow(firstOption.entryID, "optionID", firstOption.optionID);
          updateRow(firstOption.entryID, "tweet_text", firstOption.tweet_text);
          updateRow(firstOption.entryID, "options", t);
        });
      }
    };

    const alternateQuote = (row: TweetRow, direction: number) => {
      if (row.options && row.options.length > 0) {
        const currentIndex = row.options.findIndex(
          (o) => o.entryID === row.id && o.optionID === row.optionID
        );
        let nextIndex =
          (currentIndex + direction + row.options.length) % row.options.length;
        const nextOption = row.options[nextIndex];
        updateRow(row.id, "id", nextOption.entryID);
        updateRow(row.id, "optionID", nextOption.optionID);
        updateRow(row.id, "tweet_text", nextOption.tweet_text);
        setOptionsMap((prev) => ({
          ...prev,
          [nextOption.entryID]: nextOption.optionID,
        }));
      }
    };

    return (
      <Row gutter={[5, 5]} style={{ marginBottom: 16 }}>
        <div style={{ position: "absolute", marginLeft: "-45px" }}>
          <Popover
            content={
              <div style={{ width: 200 }}>
                <Select
                  style={{ width: "100%" }}
                  value={selectedAgent?.agent_uuid || "random_public"}
                  onChange={handleAgentChange}
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  placeholder="Search for an agent"
                >
                  {agentOptions.map((option, i) => (
                    <Select.Option
                      key={option.agent_uuid || i}
                      value={option.agent_uuid || ""}
                    >
                      <Avatar src={option.avatar_url} /> {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            }
            title="Select Agent"
            trigger="click"
          >
            <Avatar
              size="large"
              src={
                selectedAgent?.avatar_url ||
                "https://play-lh.googleusercontent.com/jKPgqX4JBR97fgfOYcxtPLHwNhnfdwkxOoq66cFNfc0jTqZg_ZAxKsGxSsYfgyWobAUs=w240-h480-rw"
              }
              style={{ cursor: "pointer" }}
            >
              {selectedAgent?.name?.[0] || "A"}
            </Avatar>
          </Popover>
        </div>
        <Col span={isMobile ? 12 : 16}>
          <TextArea
            value={row.tweet_text}
            onChange={(e) => {
              updateRow(row.id, "tweet_text", e.target.value);
            }}
            onKeyDown={onKeyDown}
            style={{ width: "100%" }}
            rows={3}
            ref={isLastRow ? ref : null}
            disabled={row.locked}
            placeholder={`Write as ${selectedAgent?.name}`}
          />
        </Col>
        <Col span={isMobile ? 8 : 8}>
          <Space direction="vertical" style={{ width: "auto" }}>
            <Space.Compact block style={{ width: "100%" }}>
              <Button
                onClick={() => alternateQuote(row, -1)}
                disabled={row.locked}
                icon={<LeftOutlined />}
              />
              <Button
                id={generateButtonID}
                onClick={() => generateTweet(row)}
                disabled={row.locked}
                style={{ flex: 1 }}
              >
                {row.loading ? <LoadingOutlined /> : <span>Generate</span>}
              </Button>
              <Button
                onClick={() => alternateQuote(row, 1)}
                disabled={row.locked}
                icon={<RightOutlined />}
              />
            </Space.Compact>
            <Space.Compact block>
              <Popover
                content={
                  <QuotePreview
                    username={
                      row.options.find(
                        (o) =>
                          o.entryID === row.id &&
                          o.optionID === optionsMap[o.entryID]
                      )?.quoted_username || "username"
                    }
                    text={
                      row.options.find(
                        (o) =>
                          o.entryID === row.id &&
                          o.optionID === optionsMap[o.entryID]
                      )?.quoted_text || "quoted text"
                    }
                    url={
                      row.options.find(
                        (o) =>
                          o.entryID === row.id &&
                          o.optionID === optionsMap[o.entryID]
                      )?.quoted_url || "quoted url"
                    }
                  />
                }
                title="Quoted"
                trigger="click"
              >
                <Button disabled={row.locked} icon={<MessageOutlined />} />
              </Popover>

              {row.locked ? (
                <Button
                  disabled={!row.locked}
                  onClick={() => editTweetRecall()}
                  style={{
                    flex: 1,
                    backgroundColor: "rgba(0,0,0,0.04)",
                    color: "rgba(0, 0, 0, 0.25)",
                  }}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  disabled={row.locked}
                  onClick={() => saveTweet()}
                  style={{ flex: 1 }}
                  id={saveButtonID}
                >
                  Save
                </Button>
              )}
              <Dropdown
                disabled={row.locked}
                menu={{
                  items: [
                    {
                      key: "1",
                      label: (
                        <Popover
                          content={
                            <Select
                              disabled
                              defaultValue="Random"
                              style={{ width: "100%" }}
                            >
                              <Select.Option value="Random">
                                Random
                              </Select.Option>
                            </Select>
                          }
                          title="Agent"
                          trigger="click"
                        >
                          <UserSwitchOutlined style={{ marginRight: "10px" }} />
                          {`Agent`}
                        </Popover>
                      ),
                    },
                    {
                      key: "2",
                      label: (
                        <Popover
                          content={
                            <TextArea
                              disabled={row.locked}
                              value={row.internal_notes}
                              onChange={handleInternalNotesChange}
                              style={{ width: 200 }}
                              key={forceUpdateKey}
                            />
                          }
                          title="Internal Notes"
                          trigger="click"
                          key={forceUpdateKey}
                          onOpenChange={handlePopoverVisibleChange}
                        >
                          <FormOutlined style={{ marginRight: "10px" }} />
                          {`Notes`}
                        </Popover>
                      ),
                    },
                    {
                      key: "3",
                      label: (
                        <div>
                          <DeleteOutlined style={{ marginRight: "10px" }} />
                          <span onClick={removeTweet}>Remove</span>
                        </div>
                      ),
                    },
                  ],
                }}
                placement="bottomRight"
              >
                <Button icon={<MoreOutlined />} />
              </Dropdown>
            </Space.Compact>
          </Space>
        </Col>
      </Row>
    );
  }
);

const SpriteSpreadsheetEditor: React.FC<AdvancedSpreadsheetEditorProps> = ({
  tweetText,
  replyToUrl,
  campaign,
  generateButtonID,
  saveButtonID,
}) => {
  const { rows, addRow, updateRow, removeRow } = useStackSpreadsheets(1);
  const lastRowRef = useRef<HTMLTextAreaElement>(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Tab" && !e.shiftKey) {
        e.preventDefault();
        addRow();
        setTimeout(() => {
          lastRowRef.current?.focus();
        }, 0);
      }
    },
    [addRow]
  );

  return (
    <div style={{ width: "100%" }}>
      {rows.map((row, index) => (
        <ReplyRow
          key={row.id}
          row={row}
          updateRow={updateRow}
          removeRow={removeRow}
          isLastRow={index === rows.length - 1}
          onKeyDown={handleKeyDown}
          showQuote={index === 0}
          ref={index === rows.length - 1 ? lastRowRef : undefined}
          campaign={campaign}
          tweetText={tweetText}
          replyToUrl={replyToUrl}
          generateButtonID={generateButtonID}
          saveButtonID={saveButtonID}
        />
      ))}
      <Row key={"add-row"} style={{ marginBottom: 8 }}>
        <Col span={isMobile ? 12 : 16}>
          <Button type="dashed" onClick={addRow} style={{ width: "100%" }}>
            Add Row
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SpriteSpreadsheetEditor;
