import React, { useState } from "react";
import {
  Card,
  Avatar,
  Typography,
  Space,
  Tooltip,
  Image,
  Modal,
  message,
} from "antd";
import {
  UserOutlined,
  CheckCircleFilled,
  HeartOutlined,
  RetweetOutlined,
  MessageOutlined,
  BookOutlined,
  EyeOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { formatDistanceToNow } from "date-fns";

const { Text, Title } = Typography;

export interface TweetPreview {
  url: string;
  text: string;
  media?: {
    mediaUrl: string;
    type: string;
  }[];
  views: number;
  likes: number;
  retweets: number;
  replies: number;
  quoteCount: number;
  author: {
    name: string;
    screenName: string;
    profileImageUrl: string;
    isVerified: boolean;
    url: string;
  };
  createdAt: string;
  quotedTweet?: TweetPreview | null;
  source: string;
}

interface TweetCardProps {
  tweet: TweetPreview;
}

function isValidDate(dateString: string) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

const TweetCard: React.FC<TweetCardProps> = ({ tweet }) => {
  const {
    url,
    text,
    media,
    views,
    likes,
    retweets,
    replies,
    quoteCount,
    author,
    createdAt,
    quotedTweet,
    source,
  } = tweet;

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<string | null>(null);

  const TweetEngagement: React.FC<{
    icon: React.ReactNode;
    count: number;
    text: string;
  }> = ({ icon, count, text }) => (
    <Tooltip title={text}>
      <Space>
        {icon}
        <Text>{count}</Text>
      </Space>
    </Tooltip>
  );

  const handleMediaClick = (mediaUrl: string) => {
    setSelectedMedia(mediaUrl);
    setModalVisible(true);
  };

  const renderMedia = (mediaItems: TweetPreview["media"]) => {
    if (!mediaItems || mediaItems.length === 0) return null;

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          justifyContent: "flex-start",
        }}
      >
        {mediaItems.map((item, index) => (
          <div
            key={index}
            onClick={() => handleMediaClick(item.mediaUrl)}
            style={{ cursor: "pointer" }}
          >
            {item.type === "photo" ? (
              <Image
                src={item.mediaUrl}
                alt={`Media ${index + 1}`}
                style={{ width: 120, height: 120, objectFit: "cover" }}
                preview={false}
              />
            ) : (
              <video width="120" height="120" style={{ objectFit: "cover" }}>
                <source src={item.mediaUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Card
      hoverable
      style={{ width: "100%", maxWidth: 500, margin: "0px auto" }}
      bodyStyle={{ padding: 16 }}
    >
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <Space align="start">
            <Avatar
              src={author.profileImageUrl}
              icon={<UserOutlined />}
              size={48}
            />
            <div>
              <Space>
                <a href={author.url} target="_blank" rel="noopener noreferrer">
                  <Title level={5} style={{ margin: 0 }}>
                    {author.name}
                  </Title>
                </a>
                {author.isVerified && (
                  <CheckCircleFilled style={{ color: "#1DA1F2" }} />
                )}
              </Space>
              <Text type="secondary" style={{ display: "block" }}>
                @{author.screenName}
              </Text>
            </div>
          </Space>
          <span
            onClick={
              // copy tweet URL to clipboard
              () =>
                navigator.clipboard
                  .writeText(url)
                  .then(() => {
                    message.success("Copied to clipboard");
                  })
                  .catch(() => {
                    message.error("Failed to copy");
                  })
            }
            style={{ textDecoration: "none", color: "gray" }}
          >
            <LinkOutlined /> Copy
          </span>
        </div>

        <Text
          style={{
            fontSize: "18px",
            lineHeight: "1.4",
            display: "block",
            color: "rgba(0, 0, 0, 0.85)",
            padding: "20px 0px",
            textAlign: "left",
          }}
        >
          {text}
        </Text>

        {renderMedia(media)}

        {quotedTweet && (
          <Card size="small" style={{ border: "1px solid #e8e8e8" }}>
            <TweetCard tweet={quotedTweet} />
          </Card>
        )}

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            justifyContent: "flex-start",
          }}
        >
          <TweetEngagement
            icon={<HeartOutlined />}
            count={likes}
            text="Likes"
          />
          <TweetEngagement
            icon={<RetweetOutlined />}
            count={retweets}
            text="Retweets"
          />
          <TweetEngagement
            icon={<MessageOutlined />}
            count={replies}
            text="Replies"
          />
          <TweetEngagement
            icon={<BookOutlined />}
            count={quoteCount}
            text="Quotes"
          />
          <TweetEngagement icon={<EyeOutlined />} count={views} text="Views" />
        </div>

        <div style={{ width: "100%", textAlign: "left" }}>
          <Text type="secondary" style={{ display: "inline" }}>
            {isValidDate(createdAt)
              ? formatDistanceToNow(new Date(createdAt))
              : "Unknown"}{" "}
            ago
          </Text>
          <Text type="secondary" style={{ display: "inline" }}>
            {` via ${source}`}
          </Text>
        </div>
      </Space>

      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width="auto"
        style={{ maxWidth: "90vw", maxHeight: "90vh" }}
      >
        {selectedMedia &&
          (selectedMedia.endsWith(".mp4") ? (
            <video controls style={{ maxWidth: "100%", maxHeight: "80vh" }}>
              <source src={selectedMedia} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={selectedMedia}
              alt="Full size media"
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
            />
          ))}
      </Modal>
    </Card>
  );
};

export default TweetCard;

export const sampleTweet_everything: TweetPreview = {
  url: "https://x.com/jane/status/asdf35t",
  text: "This is a sample tweet with an image and a video!",
  media: [
    {
      mediaUrl:
        "https://pbs.twimg.com/media/GZdIeutbUAAQr27?format=jpg&name=small",
      type: "photo",
    },
    {
      mediaUrl:
        "https://videos.pexels.com/video-files/3195394/3195394-uhd_2560_1440_25fps.mp4",
      type: "video",
    },
    {
      mediaUrl:
        "https://pbs.twimg.com/media/GZdIeutbUAAQr27?format=jpg&name=small",
      type: "photo",
    },
    {
      mediaUrl:
        "https://videos.pexels.com/video-files/3195394/3195394-uhd_2560_1440_25fps.mp4",
      type: "video",
    },
    {
      mediaUrl:
        "https://pbs.twimg.com/media/GZdIeutbUAAQr27?format=jpg&name=small",
      type: "photo",
    },
    {
      mediaUrl:
        "https://videos.pexels.com/video-files/3195394/3195394-uhd_2560_1440_25fps.mp4",
      type: "video",
    },
  ],
  views: 1024,
  likes: 250,
  retweets: 50,
  replies: 10,
  quoteCount: 5,
  author: {
    name: "John Doe",
    url: "https://x.com/jane",
    screenName: "johndoe",
    profileImageUrl:
      "https://pbs.twimg.com/profile_images/1796472611062595584/j5HNKiDW_bigger.jpg",
    isVerified: true,
  },
  createdAt: "2024-10-10T12:00:00Z",
  quotedTweet: {
    url: "https://x.com/jane/status/sfgg",
    text: "This is the quoted tweet text!",
    media: [],
    views: 500,
    likes: 100,
    retweets: 20,
    replies: 5,
    quoteCount: 2,
    author: {
      name: "Jane Doe",
      url: "https://x.com/jane/status/asdfasdf",
      screenName: "janedoe",
      profileImageUrl:
        "https://pbs.twimg.com/profile_images/1829700469155119105/q-z6sPVf_bigger.jpg",
      isVerified: false,
    },
    createdAt: "2024-10-09T14:00:00Z",
    quotedTweet: null,
    source: "Twitter Web App",
  },
  source: "Twitter for iPhone",
};

export const sampleTweet_onlyQuote: TweetPreview = {
  url: "https://x.com/jane/status/asdf35t",
  text: "This is a sample tweet with an image and a video!",
  media: [],
  views: 1024,
  likes: 250,
  retweets: 50,
  replies: 10,
  quoteCount: 5,
  author: {
    name: "John Doe",
    url: "https://x.com/jane",
    screenName: "johndoe",
    profileImageUrl:
      "https://pbs.twimg.com/profile_images/1796472611062595584/j5HNKiDW_bigger.jpg",
    isVerified: true,
  },
  createdAt: "2024-10-10T12:00:00Z",
  quotedTweet: {
    url: "https://x.com/jane/status/sfgg",
    text: "This is the quoted tweet text!",
    media: [],
    views: 500,
    likes: 100,
    retweets: 20,
    replies: 5,
    quoteCount: 2,
    author: {
      name: "Jane Doe",
      url: "https://x.com/jane/status/asdfasdf",
      screenName: "janedoe",
      profileImageUrl:
        "https://pbs.twimg.com/profile_images/1829700469155119105/q-z6sPVf_bigger.jpg",
      isVerified: false,
    },
    createdAt: "2024-10-09T14:00:00Z",
    quotedTweet: null,
    source: "Twitter Web App",
  },
  source: "Twitter for iPhone",
};

export const sampleTweet_noQuote: TweetPreview = {
  url: "https://x.com/jane/status/asdf35t",
  text: "This is a sample tweet with an image and a video!",
  media: [
    {
      mediaUrl:
        "https://pbs.twimg.com/media/GZdIeutbUAAQr27?format=jpg&name=small",
      type: "photo",
    },
    {
      mediaUrl:
        "https://videos.pexels.com/video-files/3195394/3195394-uhd_2560_1440_25fps.mp4",
      type: "video",
    },
    {
      mediaUrl:
        "https://pbs.twimg.com/media/GZdIeutbUAAQr27?format=jpg&name=small",
      type: "photo",
    },
    {
      mediaUrl:
        "https://videos.pexels.com/video-files/3195394/3195394-uhd_2560_1440_25fps.mp4",
      type: "video",
    },
    {
      mediaUrl:
        "https://pbs.twimg.com/media/GZdIeutbUAAQr27?format=jpg&name=small",
      type: "photo",
    },
    {
      mediaUrl:
        "https://videos.pexels.com/video-files/3195394/3195394-uhd_2560_1440_25fps.mp4",
      type: "video",
    },
  ],
  views: 1024,
  likes: 250,
  retweets: 50,
  replies: 10,
  quoteCount: 5,
  author: {
    name: "John Doe",
    url: "https://x.com/jane",
    screenName: "johndoe",
    profileImageUrl:
      "https://pbs.twimg.com/profile_images/1796472611062595584/j5HNKiDW_bigger.jpg",
    isVerified: true,
  },
  createdAt: "2024-10-10T12:00:00Z",
  quotedTweet: null,
  source: "Twitter for iPhone",
};

export const sampleTweet_pureText: TweetPreview = {
  url: "https://x.com/jane/status/asdf35t",
  text: "This is a sample tweet with an image and a video!",
  media: [],
  views: 1024,
  likes: 250,
  retweets: 50,
  replies: 10,
  quoteCount: 5,
  author: {
    name: "John Doe",
    url: "https://x.com/jane",
    screenName: "johndoe",
    profileImageUrl:
      "https://pbs.twimg.com/profile_images/1796472611062595584/j5HNKiDW_bigger.jpg",
    isVerified: true,
  },
  createdAt: "2024-10-10T12:00:00Z",
  quotedTweet: null,
  source: "Twitter for iPhone",
};
