import React, { useEffect, useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { message } from "antd";

const { Title } = Typography;

const LoginPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const { checkAPIKeyValid } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("auth");
    if (token) {
      setApiKey(token);
      handleLogin(token);
    }
  }, [location]);

  const handleLogin = async (token: string) => {
    setLoading(true);
    const isValid = await checkAPIKeyValid(token);
    setLoading(false);
    if (isValid) {
      navigate("/write");

      message.success("API key is valid");
    } else {
      message.error("API key is invalid");
    }
  };

  const onFinish = () => {
    handleLogin(apiKey);
  };

  return (
    <div style={{ maxWidth: 400, margin: "0 auto", padding: "2rem" }}>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Title level={2} style={{ textAlign: "center" }}>
        🍀 TweetsMob
      </Title>
      <span style={{ fontSize: "1rem" }}>
        Create your Account by messaging the Telegram bot 👉{" "}
        <a href="https://t.me/TweetMob_bot" target="_blank">
          t.me/tweetmob_bot
        </a>
      </span>
      <br />
      <br />
      <br />
      <br />
      <Form onFinish={onFinish}>
        <Form.Item
          rules={[{ required: true, message: "Please enter your API key" }]}
        >
          <label style={{ marginBottom: "10px" }}>Enter API Key</label>
          <Input.Password
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            placeholder="Your API Key"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
