import React, { useState, useEffect, ReactNode } from "react";
import { Input, Row, Col, Typography, Popover } from "antd";
import { TABLE_PICKER_VIEW_HELP_TEXT } from "../constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useAuth } from "../hooks/useAuth";

const { Title } = Typography;

interface TablePickerViewProps {
  title: string;
  description?: string | ReactNode;
  cookieSlug: string;
}

const TablePickerView: React.FC<TablePickerViewProps> = ({
  title,
  cookieSlug,
  description = "",
}) => {
  const [url, setUrl] = useState<string>("");
  const { myProfile } = useAuth();

  useEffect(() => {
    const storedUrl = localStorage.getItem(cookieSlug);
    if (storedUrl) {
      setUrl(storedUrl);
    }
  }, [cookieSlug]);

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    localStorage.setItem(cookieSlug, newUrl);
  };

  return (
    <div>
      <Col>
        <Col>
          <Title level={4}>{title}</Title>
          <p style={{ textAlign: "center" }}>{description}</p>
        </Col>
        <Col flex="auto" style={{ padding: "0px 10px" }}>
          <Input
            value={url}
            onChange={handleUrlChange}
            placeholder="Enter table view URL"
            prefix={
              <Popover content={TABLE_PICKER_VIEW_HELP_TEXT}>
                <InfoCircleOutlined />
              </Popover>
            }
            suffix={
              <a
                href={myProfile?.nocodb_host_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open
              </a>
            }
            style={{ maxWidth: "500px" }}
          />
        </Col>
      </Col>
      <div style={{ marginTop: "16px", height: "calc(100vh - 100px)" }}>
        {url && (
          <iframe
            src={url}
            width="100%"
            height="100%"
            style={{ border: "none" }}
            title={title}
          />
        )}
      </div>
    </div>
  );
};

export default TablePickerView;
