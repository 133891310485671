import { useEffect, useState } from "react";
import TablePickerView from "../components/TablePickerView";
import { useAuth } from "../hooks/useAuth";

const QuotesPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { myProfile } = useAuth();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  if (!isLoaded || !myProfile) {
    return null; // or a loading indicator
  }
  return (
    <>
      <br />
      <br />
      <br />
      <TablePickerView
        title="Quotes"
        cookieSlug={`nocodb_quotes_view_${myProfile.telegram_username}`}
        description={
          <div style={{ lineHeight: "1.3rem" }}>
            <span>Add your tweets for crowd to promote.</span>
            <br />
            <span>
              Telegram{" "}
              <a href="https://t.me/TweetMob_bot" target="_blank">
                @TweetMob_bot
              </a>
              {` `}
              <code style={{ backgroundColor: "#e1e4ff" }}>{`/quote`}</code>
              {" to add posts."}
            </span>
            <br />
          </div>
        }
      />
    </>
  );
};

export default QuotesPage;
