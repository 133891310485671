import React, { useState, useCallback, useMemo } from "react";
import {
  Input,
  Button,
  Popover,
  Typography,
  Divider,
  Space,
  Row,
  Col,
  Tag,
  Checkbox,
  message,
  notification,
} from "antd";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import {
  sampleTweet_everything,
  sampleTweet_noQuote,
  sampleTweet_onlyQuote,
  sampleTweet_pureText,
  TweetPreview,
} from "../components/TweetCard";
import TweetResponder from "../components/TweetResponder";
import { debounce } from "lodash";
import { fetchTwitterBySearch, useTweetStore } from "../hooks/useTweetStore";
import StickyActionBar from "../components/StickyActionBar";
import { useAuth } from "../hooks/useAuth";
import QuickTweet from "../components/QuickTweet";

const { Text } = Typography;

const generateButtonID = "search-strategy-generate-button";
const saveButtonID = "search-strategy-save-button";
const loadMoreButtonID = "search-strategy-load-more-button";

export const SearchStrategy = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [tweets, setTweets] = useState<TweetPreview[]>([]);
  const [cursor, setCursor] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { apiKey } = useAuth();
  const [isGeneratingAll, setIsGeneratingAll] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const handleSearch = useCallback(async () => {
    if (!apiKey) return;
    setIsLoading(true);
    const { cursor: returnedCursor, tweets: returnedTweets } =
      await fetchTwitterBySearch({
        token: apiKey,
        payload: {
          query: searchQuery,
          cursor,
        },
      });
    setCursor(returnedCursor);
    setTweets((prev) => [...prev, ...returnedTweets]);
    setIsLoading(false);
    message.success(`Added ${returnedTweets.length} tweets to bottom of page`);
  }, [searchQuery, cursor]);

  // const handleMultiLoad = async () => {
  //   setIsLoading(true);
  //   const loadMoreButton = document.getElementById(loadMoreButtonID);
  //   if (!loadMoreButton) {
  //     message.error("Load More button not found");
  //     setIsLoading(false);
  //     return;
  //   }

  //   for (let i = 0; i < 10; i++) {
  //     console.log(`cursor=${cursor}`);
  //     loadMoreButton.click();
  //     await new Promise((resolve) => setTimeout(resolve, 5000));
  //     if (!cursor) {
  //       break;
  //     }
  //   }

  //   setIsLoading(false);
  // };

  const tweetCount = useMemo(() => tweets.length, [tweets]);

  const renderTweets = useMemo(
    () =>
      tweets.map((tweet, index) => (
        <React.Fragment key={`${tweet.url}-${index}`}>
          <TweetResponder
            tweet={tweet}
            generateButtonID={generateButtonID}
            saveButtonID={saveButtonID}
          />
          {index < tweets.length - 1 && <Divider />}
        </React.Fragment>
      )),
    [tweets]
  );

  const generateAll = async () => {
    setIsGeneratingAll(true);
    const buttons = document.querySelectorAll(`#${generateButtonID}`);
    console.log("Buttons", buttons);
    let count = 0;
    // @ts-ignore
    for (const button of buttons) {
      count += 1;
      button.click();
      message.success(`Generated reply ${count}/${buttons.length}`);
      // sleep 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
    setIsGeneratingAll(false);
    notification.success({
      message: "Successfully Generated",
      description: `Generated ${count} replies`,
      duration: 30,
    });
  };

  const saveAll = async () => {
    const buttons = document.querySelectorAll(`#${saveButtonID}`);
    // @ts-ignore
    for (const button of buttons) {
      button.click();
    }
    notification.success({
      message: "Successfully Saved",
      description: `Saved all replies`,
    });
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {contextHolder}
      <div>
        <h1>Search Strategy</h1>
        <p>Search Twitter and reply to most recent tweets</p>
      </div>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div style={{ margin: "0px 10px" }}>
          <Input.Search
            placeholder="Enter search query"
            allowClear
            size="large"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onSearch={handleSearch}
            style={{ maxWidth: "600px" }}
            loading={isLoading}
            prefix={
              <Popover
                content={
                  <span>
                    Learn how to use Twitter's query syntax.{" "}
                    <a
                      href="https://developer.x.com/en/docs/x-api/v1/rules-and-filtering/search-operators"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      More info
                    </a>
                  </span>
                }
                title="Search Syntax"
              >
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Popover>
            }
          />
          <Text type="secondary" style={{ display: "block", marginTop: "8px" }}>
            Showing {tweetCount} tweets
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={generateAll}
              loading={isGeneratingAll}
              style={{ borderRadius: "5px 0px 0px 5px" }}
              disabled={tweets.length === 0}
            >
              Generate All
            </Button>

            <Button
              onClick={saveAll}
              style={{ borderRadius: "0px 5px 5px 0px" }}
              disabled={tweets.length === 0}
            >
              Save All
            </Button>
          </div>
        </div>
        <Divider />
        {renderTweets}
        <div style={{ marginBottom: "80px" }}>
          <Button
            disabled={tweets.length === 0}
            onClick={handleSearch}
            loading={isLoading}
            id={loadMoreButtonID}
          >
            Load More
          </Button>
          {/* <Button
            disabled={tweets.length === 0}
            onClick={handleMultiLoad}
            loading={isLoading}
          >
            Load 10x
          </Button> */}
        </div>
      </Space>
      <QuickTweet />
    </div>
  );
};
