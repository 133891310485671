import { create } from "zustand";

interface AgentStoreState {
  agents: Agent[];
  addAgents: (agents: Agent[]) => void;
}

export interface Agent {
  agent_uuid: string;
  avatar_url: string;
  name: string;
  internal_notes: string;
  twitter_url: string;
  twitter_username: string;
  teams: string[];
  status: string;
  tweet_timeline?: string;
}

export const useAgentStore = create<AgentStoreState>((set) => ({
  agents: [],
  addAgents: (agents) =>
    set((state) => ({ agents: state.agents.concat(agents) })),
}));

export const createAgent = async ({
  token,
  payload,
}: {
  token: string;
  payload: {
    agent_uuid: string;
  };
}) => {
  const body = {
    route: "POST/create_agent",
    token: token,
    payload,
  };
  try {
    const response = await fetch(
      "https://api.legions.bot/api/w/run-sybil/jobs/run_wait_result/f/f/runsybil/rest_api_runsybil",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer mXkec9NMmJV4gBE0xVUnzVlrUZLiVXfD",
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create agent");
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error creating agent:", error);
    return null;
  }
};

export const listAgents = async ({ token }: { token: string }) => {
  const body = {
    route: "GET/agents",
    token: token,
  };
  try {
    const response = await fetch(
      "https://api.legions.bot/api/w/run-sybil/jobs/run_wait_result/f/f/runsybil/rest_api_runsybil",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer mXkec9NMmJV4gBE0xVUnzVlrUZLiVXfD",
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to list agents");
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error list agents:", error);
    return [];
  }
};

export const tweetNow = async ({
  token,
  payload,
}: {
  token: string;
  payload: {
    reply_to_url: string;
    tweet_text: string;
    campaign: string;
    agent_username: string;
    agent_uuid: string;
  };
}) => {
  const body = {
    route: "POST/tweet_now",
    token: token,
    payload,
  };
  try {
    const response = await fetch(
      "https://api.legions.bot/api/w/run-sybil/jobs/run_wait_result/f/f/runsybil/rest_api_runsybil",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer mXkec9NMmJV4gBE0xVUnzVlrUZLiVXfD",
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to list agents");
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error list agents:", error);
    return [];
  }
};

export function calculateSpeedScore(tweet_timeline: string): {
  score: number;
  memo: string;
} {
  // Parse tweet timeline and convert timestamps to numbers
  const timestamps = tweet_timeline.split(",").map(Number);
  const now = Math.floor(Date.now() / 1000);

  // Define time windows in seconds
  const oneHour = 3600;
  const fourHours = 14400;

  // Filter timestamps within the last 1 hour and 4 hours
  const tweetsInLastHour = timestamps.filter(
    (timestamp) => now - timestamp <= oneHour
  ).length;
  const tweetsInLastFourHours = timestamps.filter(
    (timestamp) => now - timestamp <= fourHours
  ).length;

  // Calculate speed score based on conditions
  let score: number;
  let memo: string;

  // Calculate score based on thresholds
  score = (tweetsInLastHour / 5 + tweetsInLastFourHours / 20) / 2;

  console.log(`tweetsInLastHour: ${tweetsInLastHour}
tweetsInLastFourHours: ${tweetsInLastFourHours}
score: ${score}
    `);

  // Determine memo message with priority for recent tweets (last hour)
  if (tweetsInLastHour >= 5) {
    memo = `${tweetsInLastHour} tweets in last hour, danger excessive`;
  } else if (tweetsInLastHour >= 4) {
    memo = `${tweetsInLastHour} tweets in last hour, warning slow down`;
  } else if (tweetsInLastFourHours > 20) {
    memo = `${tweetsInLastFourHours} tweets in 4 hours, danger excessive`;
  } else if (tweetsInLastFourHours >= 15) {
    memo = `${tweetsInLastFourHours} tweets in 4 hours, warning slow down`;
  } else {
    memo = `${tweetsInLastFourHours} tweets in 4 hours, looks good`;
  }

  return { score, memo };
}

export const deployCampaign = async ({
  token,
  payload,
}: {
  token: string;
  payload: {
    campaign: string;
  };
}) => {
  const body = {
    route: "POST/deploy_campaign",
    token: token,
    payload,
  };
  try {
    const response = await fetch(
      "https://api.legions.bot/api/w/run-sybil/jobs/run_wait_result/f/f/runsybil/rest_api_runsybil",

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer mXkec9NMmJV4gBE0xVUnzVlrUZLiVXfD",
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to deploy campaign");
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error deploy campaign:", error);
    return [];
  }
};

const PUBLIC_ICON =
  "https://play-lh.googleusercontent.com/jKPgqX4JBR97fgfOYcxtPLHwNhnfdwkxOoq66cFNfc0jTqZg_ZAxKsGxSsYfgyWobAUs=w240-h480-rw";

export function getRandomFromAgentsSet(
  agents: Agent[],
  selectedAgentUuid: string
) {
  // Helper function to get a random item from an array
  const getRandomFromArray = (arr: any[]) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };

  // Default public agent
  const publicAgent: Agent = {
    agent_uuid: "random_public",
    avatar_url: PUBLIC_ICON,
    name: "Random Public Agent",
    internal_notes: "",
    twitter_url: "",
    twitter_username: "",
    teams: [],
    status: "active",
  };

  // Case 1: Random Public Agent
  if (selectedAgentUuid === "random_public") {
    return publicAgent;
  }

  // Case 2: Random Team Agent
  if (selectedAgentUuid.startsWith("team_")) {
    const teamName = selectedAgentUuid.slice(5); // Remove 'team_' prefix
    const teamAgents = agents.filter((agent) => agent.teams.includes(teamName));
    if (teamAgents.length > 0) {
      const randomTeamAgent = getRandomFromArray(teamAgents);
      return randomTeamAgent;
    }
  }

  // Case 3: Random from all team agents
  if (selectedAgentUuid === "team") {
    const teamAgents = agents.filter((agent) => agent.teams.length > 0);
    if (teamAgents.length > 0) {
      const randomTeamAgent = getRandomFromArray(teamAgents);
      return randomTeamAgent;
    }
  }

  // Case 4: Specific Agent
  const specificAgent = agents.find(
    (agent) => agent.agent_uuid === selectedAgentUuid
  );
  if (specificAgent) {
    return specificAgent;
  }

  // If no matching agent is found, return the public agent
  return publicAgent;
}
