import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Input,
  notification,
  Popover,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import { saveTweetsToCampaign, useTweetStore } from "../hooks/useTweetStore";
import { useShallow } from "zustand/react/shallow";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce, set, uniq } from "lodash";
import { useAuth } from "../hooks/useAuth";
import { useAgentStore } from "../hooks/useAgentStore";

const { Text } = Typography;

const StickyActionBar = () => {
  const {
    queuedTweets,
    removeTweetFromQueue,
    campaign,
    setCampaign,
    prompt,
    setPrompt,
    quoteSettings,
    setQuoteSettings,
    mentionSettings,
    setMentionSettings,
    agentSettings,
    setAgentSettings,
  } = useTweetStore(
    useShallow((state) => ({
      queuedTweets: state.queuedTweets,
      removeTweetFromQueue: state.removeTweetFromQueue,
      campaign: state.campaign,
      setCampaign: state.setCampaign,
      prompt: state.prompt,
      setPrompt: state.setPrompt,
      quoteSettings: state.quoteSettings,
      setQuoteSettings: state.setQuoteSettings,
      mentionSettings: state.mentionSettings,
      setMentionSettings: state.setMentionSettings,
      agentSettings: state.agentSettings,
      setAgentSettings: state.setAgentSettings,
    }))
  );
  const [quoteTagsInputValue, setQuoteTagsInputValue] = useState("");
  const [mentionTagsInputValue, setMentionTagsInputValue] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [localCampaign, setLocalCampaign] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const { apiKey } = useAuth();
  const [api, contextHolder] = notification.useNotification();
  const { agents } = useAgentStore(
    useShallow((state) => ({ agents: state.agents }))
  );

  useEffect(() => {
    setLocalCampaign(campaign);
  }, [campaign]);

  const handleQuoteTagsInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value.toLowerCase().replace(" ", "_").trim();
    setQuoteTagsInputValue(value);
  };
  const handleMentionTagsInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value.toLowerCase().replace(" ", "_").trim();
    setMentionTagsInputValue(value);
  };

  const handleQuoteTagsInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" || e.key === ",") {
        e.preventDefault();
        if (quoteTagsInputValue.trim()) {
          setQuoteSettings({
            ...quoteSettings,
            tags: quoteSettings.tags
              .filter((t) => t !== quoteTagsInputValue.toLowerCase().trim())
              .concat([quoteTagsInputValue.toLowerCase().trim()]),
          });
          setQuoteTagsInputValue("");
        }
      }
    },
    [quoteTagsInputValue, quoteSettings, setQuoteSettings]
  );
  const handleMentionTagsInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" || e.key === ",") {
        e.preventDefault();
        if (mentionTagsInputValue.trim()) {
          setMentionSettings({
            ...mentionSettings,
            tags: mentionSettings.tags
              .filter((t) => t !== mentionTagsInputValue.toLowerCase().trim())
              .concat([mentionTagsInputValue.toLowerCase().trim()]),
          });
          setMentionTagsInputValue("");
        }
      }
    },
    [mentionTagsInputValue, mentionSettings, setMentionSettings]
  );

  const handleRemoveQuoteTag = useCallback(
    (tagToRemove: string) => {
      setQuoteSettings({
        ...quoteSettings,
        tags: quoteSettings.tags.filter((tag) => tag !== tagToRemove),
      });
    },
    [quoteSettings, setQuoteSettings]
  );
  const handleRemoveMentionTag = useCallback(
    (tagToRemove: string) => {
      setMentionSettings({
        ...mentionSettings,
        tags: mentionSettings.tags.filter((tag) => tag !== tagToRemove),
      });
    },
    [mentionSettings, setMentionSettings]
  );

  const debouncedSetCampaign = useMemo(
    () => debounce((value: string) => setCampaign(value), 300),
    []
  );

  const publishToCampaign = async () => {
    setIsSaving(true);
    if (!apiKey) return;
    const savedEntries = await saveTweetsToCampaign({
      token: apiKey,
      payload: {
        tweets: queuedTweets.map((t) => {
          return {
            entryID: t.id,
            tweet_text: t.tweet_text,
            reply_to_url: t.reply_to_url,
            campaign: t.campaign,
            internal_notes: t.internal_notes,
            agent_uuid: t.agent_uuid,
            agent_name: t.agent_name,
          };
        }),
      },
    });
    for (const entryID of savedEntries) {
      removeTweetFromQueue(entryID);
    }
    setIsSaving(false);
    api.success({
      message: "Successfully Saved Tweets to Campaign",
      description: `You can see them in your database`,
      duration: 30,
    });
  };

  const agentOptions = useMemo(() => {
    const options = [
      { agent_uuid: "random_public", name: "Random Public" },
      {
        agent_uuid: "team",
        name: "Random Team",
        disabled: agents.length === 0,
      },
    ];

    // Get unique teams without using Set
    const teams = uniq(agents.flatMap((agent) => agent.teams));
    teams.forEach((team) => {
      options.push({
        agent_uuid: `team_${team}`,
        name: `Team - ${team}`,
        disabled: agents.length === 0,
      });
    });

    agents.forEach((agent) => {
      options.push({ agent_uuid: agent.agent_uuid, name: agent.name });
    });

    return options;
  }, [agents]);

  const handleAgentChange = (value: string) => {
    const selectedAgent = agentOptions.find(
      (option) => option.agent_uuid === value
    );
    if (selectedAgent) {
      setAgentSettings({
        ...agentSettings,
        selectedAgent: {
          agent_uuid: selectedAgent.agent_uuid,
          name: selectedAgent.name,
        },
      });
    }
  };

  const filterOption = (
    input: string,
    option?: { children: string; value: string }
  ) => {
    return (
      (option?.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
      0
    );
  };

  return (
    <div
      style={{
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
        padding: "16px",
        borderTop: "1px solid #f0f0f0",
      }}
    >
      {contextHolder}
      <Row gutter={[16, 16]} align="middle">
        <Col xs={24} sm={12}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "200px",
              }}
            >
              <Button style={{ marginRight: 8 }}>
                <Checkbox
                  checked={quoteSettings.checked}
                  onChange={(e) =>
                    setQuoteSettings({
                      ...quoteSettings,
                      checked: e.target.checked,
                    })
                  }
                />
                <Popover
                  content={
                    <Input.Group compact>
                      <Text style={{ marginBottom: 5 }}>Quote tags - csv</Text>
                      <Input.Group compact>
                        <Input
                          value={quoteTagsInputValue}
                          onChange={handleQuoteTagsInputChange}
                          onKeyDown={handleQuoteTagsInputKeyDown}
                          placeholder="Add tags"
                          style={{ width: "100%" }}
                        />
                      </Input.Group>
                      <div style={{ marginTop: "8px" }}>
                        {quoteSettings.tags.map((tag) => (
                          <Tag
                            key={tag}
                            closable
                            onClose={() => handleRemoveQuoteTag(tag)}
                          >
                            {tag}
                          </Tag>
                        ))}
                      </div>
                    </Input.Group>
                  }
                  trigger="click"
                >
                  Quote
                </Popover>
              </Button>
              <Button style={{ marginRight: 8 }}>
                <Checkbox
                  checked={mentionSettings.checked}
                  onChange={(e) =>
                    setMentionSettings({
                      ...mentionSettings,
                      checked: e.target.checked,
                    })
                  }
                />
                <Popover
                  content={
                    <Input.Group compact>
                      <Text style={{ marginBottom: 5 }}>
                        Mention tags - csv
                      </Text>
                      <Input.Group compact>
                        <Input
                          value={mentionTagsInputValue}
                          onChange={handleMentionTagsInputChange}
                          onKeyDown={handleMentionTagsInputKeyDown}
                          placeholder="Add tags"
                          style={{ width: "100%" }}
                        />
                      </Input.Group>
                      <div style={{ marginTop: "8px" }}>
                        {mentionSettings.tags.map((tag) => (
                          <Tag
                            key={tag}
                            closable
                            onClose={() => handleRemoveMentionTag(tag)}
                          >
                            {tag}
                          </Tag>
                        ))}
                      </div>
                    </Input.Group>
                  }
                  trigger="click"
                >
                  Mention
                </Popover>
              </Button>
              <Button style={{ marginRight: 8 }}>
                <Checkbox
                  checked={
                    agentSettings.selectedAgent.agent_uuid !== "random_public"
                  }
                />
                <Popover
                  content={
                    <div>
                      <Text style={{ marginBottom: 5 }}>Select Agent</Text>
                      <Select
                        style={{ width: "100%" }}
                        value={agentSettings.selectedAgent.agent_uuid}
                        onChange={handleAgentChange}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        placeholder="Search for an agent"
                      >
                        {agentOptions.map((option) => (
                          <Select.Option
                            key={option.agent_uuid}
                            value={option.agent_uuid}
                            disabled={option.disabled}
                          >
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  }
                  trigger="click"
                >
                  Agent
                </Popover>
              </Button>
            </div>
            <Input.TextArea
              onChange={(e) => setPrompt(e.target.value)}
              autoSize={{ minRows: 2, maxRows: 6 }}
              style={{ width: "100%" }}
              value={prompt}
              placeholder="Your prompt here generates replies"
            />
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <Space
            direction={isMobile ? "vertical" : "horizontal"}
            size="small"
            style={{ width: "100%", justifyContent: "flex-end" }}
          >
            <Input
              placeholder="Campaign Name"
              value={localCampaign}
              onChange={(e) => {
                const value = e.target.value.toLowerCase().replace(" ", "_");
                setLocalCampaign(value);
                debouncedSetCampaign(value);
              }}
              style={{ width: isMobile ? "100%" : 200 }}
            />
            <Button
              type="primary"
              icon={<SearchOutlined />}
              style={{ width: isMobile ? "100%" : "auto" }}
              loading={isSaving}
              onClick={publishToCampaign}
              disabled={queuedTweets.length === 0}
            >
              Publish {queuedTweets.length} to Campaign
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default StickyActionBar;
