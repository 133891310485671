import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import TablePickerView from "../components/TablePickerView";
import { useAuth } from "../hooks/useAuth";

const { TabPane } = Tabs;

const ProspectsPage: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { myProfile } = useAuth();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  if (!isLoaded || !myProfile) {
    return null; // or a loading indicator
  }
  return (
    <>
      <br />
      <br />
      <br />
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Tweets" key="1">
          <TablePickerView
            title="Bookmarked Tweets"
            cookieSlug={`nocodb_bookmarked_tweets_view_${myProfile.telegram_username}`}
            description={
              <span>
                Telegram{" "}
                <a href="https://t.me/TweetMob_bot" target="_blank">
                  @TweetMob_bot
                </a>
                {` `}
                <code
                  style={{ backgroundColor: "#e1e4ff" }}
                >{`/bookmark`}</code>
                {" to save tweets"}
              </span>
            }
          />
        </TabPane>
        <TabPane tab="Profiles" key="2">
          <TablePickerView
            title="Bookmarked Profiles"
            cookieSlug={`nocodb_bookmarked_profiles_view_${myProfile.telegram_username}`}
            description={
              <span>
                Telegram{" "}
                <a href="https://t.me/TweetMob_bot" target="_blank">
                  @TweetMob_bot
                </a>
                {` `}
                <code
                  style={{ backgroundColor: "#e1e4ff" }}
                >{`/bookmark`}</code>
                {" to save profiles"}
              </span>
            }
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ProspectsPage;
