import { TweetPreview } from "../components/TweetCard";

export interface TweetDetailConversationType {
  data: Data;
}

export function parseTwitterThread(input: TweetDetailConversationType): {
  cursor: string | undefined;
  tweets: TweetPreview[];
} {
  const tweets: TweetPreview[] = [];
  let cursor: string | undefined;

  const instructions =
    input.data.threaded_conversation_with_injections_v2.instructions;

  for (const instruction of instructions) {
    if (instruction.type === "TimelineAddEntries") {
      for (const entry of instruction.entries || []) {
        if (entry.entryId.startsWith("tweet-")) {
          const tweetResult = entry.content?.itemContent?.tweet_results?.result;
          if (tweetResult) {
            tweets.push(convertToTweetPreview(tweetResult));
          }
        } else if (entry.entryId.startsWith("conversationthread-")) {
          const items = entry.content?.items || [];
          for (const item of items) {
            const tweetResult = item.item?.itemContent?.tweet_results?.result;
            if (tweetResult) {
              tweets.push(convertToTweetPreview(tweetResult));
            }
          }
        } else if (entry.entryId.startsWith("cursor-bottom-")) {
          cursor = entry.content?.itemContent?.value;
        }
      }
    }
  }

  return { cursor, tweets };
}

function convertToTweetPreview(tweetResult: any): TweetPreview {
  const legacy = tweetResult.legacy || {};
  const user = tweetResult.core?.user_results?.result?.legacy || {};

  const tweetPreview: TweetPreview = {
    url: `https://twitter.com/${user.screen_name || "unknown"}/status/${
      legacy.id_str || ""
    }`,
    text: legacy.full_text || "",
    views: parseInt(tweetResult.views?.count || "0"),
    likes: legacy.favorite_count || 0,
    retweets: legacy.retweet_count || 0,
    replies: legacy.reply_count || 0,
    quoteCount: legacy.quote_count || 0,
    author: {
      name: user.name || "Unknown",
      screenName: user.screen_name || "unknown",
      profileImageUrl: user.profile_image_url_https || "",
      isVerified:
        tweetResult.core?.user_results?.result?.is_blue_verified ||
        user.verified ||
        false,
      url: `https://twitter.com/${user.screen_name || "unknown"}`,
    },
    createdAt: legacy.created_at || "",
    source: legacy.source || "",
  };

  // Handle media
  const mediaEntities = legacy.entities?.media;
  if (mediaEntities && mediaEntities.length > 0) {
    tweetPreview.media = mediaEntities.map((media: any) => ({
      mediaUrl: media.media_url_https || "",
      type: media.type || "",
    }));
  }

  // Handle quoted tweet
  if (legacy.is_quote_status && tweetResult.quoted_status_result) {
    try {
      tweetPreview.quotedTweet = convertToTweetPreview(
        tweetResult.quoted_status_result.result
      );
    } catch (error) {
      console.error("Error processing quoted tweet:", error);
      tweetPreview.quotedTweet = null;
    }
  }

  return tweetPreview;
}

interface Data {
  threaded_conversation_with_injections_v2: Threadedconversationwithinjectionsv2;
}
interface Threadedconversationwithinjectionsv2 {
  instructions: Instruction[];
}
interface Instruction {
  type: string;
  entries?: Entry[];
  direction?: string;
}
interface Entry {
  entryId: string;
  sortIndex: string;
  content: Content;
}
interface Content {
  entryType: string;
  __typename: string;
  itemContent?: ItemContent;
  items?: Item2[];
  displayType?: string;
  clientEventInfo?: ClientEventInfo2;
}
interface ClientEventInfo2 {
  details: Details2;
}
interface Details2 {
  conversationDetails: ConversationDetails;
}
interface Item2 {
  entryId: string;
  item: Item;
}
interface Item {
  itemContent: ItemContent2;
  clientEventInfo: ClientEventInfo;
}
interface ClientEventInfo {
  details: Details;
}
interface Details {
  conversationDetails: ConversationDetails;
  timelinesDetails: TimelinesDetails;
}
interface TimelinesDetails {
  controllerData: string;
}
interface ConversationDetails {
  conversationSection: string;
}
interface ItemContent2 {
  itemType: string;
  __typename: string;
  tweet_results: Tweetresults2;
  tweetDisplayType: string;
}
interface Tweetresults2 {
  result: Result4;
}
interface Result4 {
  __typename: string;
  rest_id: string;
  has_birdwatch_notes: boolean;
  core: Core2;
  unmention_data: Affiliateshighlightedlabel;
  edit_control: Editcontrol;
  is_translatable: boolean;
  views: Views;
  source: string;
  legacy: Legacy5;
  quick_promote_eligibility: Quickpromoteeligibility;
}
interface Legacy5 {
  bookmark_count: number;
  bookmarked: boolean;
  created_at: string;
  conversation_id_str: string;
  display_text_range: number[];
  entities: Entities4;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  in_reply_to_screen_name: string;
  in_reply_to_status_id_str: string;
  in_reply_to_user_id_str: string;
  is_quote_status: boolean;
  lang: string;
  quote_count: number;
  reply_count: number;
  retweet_count: number;
  retweeted: boolean;
  user_id_str: string;
  id_str: string;
}
interface Entities4 {
  user_mentions: Usermention[];
  urls: any[];
  hashtags: any[];
  symbols: any[];
}
interface Usermention {
  id_str: string;
  name: string;
  screen_name: string;
  indices: number[];
}
interface Core2 {
  user_results: Userresults2;
}
interface Userresults2 {
  result: Result3;
}
interface Result3 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  legacy: Legacy4;
  professional?: Professional2;
}
interface Professional2 {
  rest_id: string;
  professional_type: string;
  category: Category[];
}
interface Legacy4 {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities3;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: string[];
  possibly_sensitive: boolean;
  profile_banner_url?: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  verified: boolean;
  want_retweets: boolean;
  withheld_in_countries: any[];
  url?: string;
}
interface Entities3 {
  description: Description;
  url?: Url2;
}
interface ItemContent {
  itemType: string;
  __typename: string;
  tweet_results?: Tweetresults;
  tweetDisplayType?: string;
  hasModeratedReplies?: boolean;
  value?: string;
  cursorType?: string;
}
interface Tweetresults {
  result: Result2;
}
interface Result2 {
  __typename: string;
  rest_id: string;
  has_birdwatch_notes: boolean;
  core: Core;
  card: Card;
  unmention_data: Affiliateshighlightedlabel;
  edit_control: Editcontrol;
  is_translatable: boolean;
  views: Views;
  source: string;
  legacy: Legacy3;
  quick_promote_eligibility: Quickpromoteeligibility;
}
interface Quickpromoteeligibility {
  eligibility: string;
}
interface Legacy3 {
  bookmark_count: number;
  bookmarked: boolean;
  created_at: string;
  conversation_id_str: string;
  display_text_range: number[];
  entities: Entities2;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  is_quote_status: boolean;
  lang: string;
  quote_count: number;
  reply_count: number;
  retweet_count: number;
  retweeted: boolean;
  scopes: Scopes;
  user_id_str: string;
  id_str: string;
}
interface Scopes {
  followers: boolean;
}
interface Entities2 {
  user_mentions: any[];
  urls: any[];
  hashtags: any[];
  symbols: Symbol[];
}
interface Symbol {
  indices: number[];
  text: string;
}
interface Views {
  count: string;
  state: string;
}
interface Editcontrol {
  edit_tweet_ids: string[];
  editable_until_msecs: string;
  is_edit_eligible: boolean;
  edits_remaining: string;
}
interface Card {
  rest_id: string;
  legacy: Legacy2;
}
interface Legacy2 {
  binding_values: Bindingvalue[];
  card_platform: Cardplatform;
  name: string;
  url: string;
  user_refs_results: any[];
}
interface Cardplatform {
  platform: Platform;
}
interface Platform {
  audience: Audience;
  device: Device;
}
interface Device {
  name: string;
  version: string;
}
interface Audience {
  name: string;
}
interface Bindingvalue {
  key: string;
  value: Value;
}
interface Value {
  string_value: string;
  type: string;
  scribe_key?: string;
}
interface Core {
  user_results: Userresults;
}
interface Userresults {
  result: Result;
}
interface Result {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  legacy: Legacy;
  professional: Professional;
}
interface Professional {
  rest_id: string;
  professional_type: string;
  category: Category[];
}
interface Category {
  id: number;
  name: string;
  icon_name: string;
}
interface Legacy {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: any[];
  possibly_sensitive: boolean;
  profile_banner_url: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  url: string;
  verified: boolean;
  verified_type: string;
  want_retweets: boolean;
  withheld_in_countries: any[];
}
interface Entities {
  description: Description;
  url: Url2;
}
interface Url2 {
  urls: Url[];
}
interface Url {
  display_url: string;
  expanded_url: string;
  url: string;
  indices: number[];
}
interface Description {
  urls: any[];
}
interface Affiliateshighlightedlabel {}
