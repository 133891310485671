import { create } from "zustand";
import {
  ListTimelineType,
  parseListTimeline,
} from "../types/ListTimelineTypes";
import { TweetPreview } from "../components/TweetCard";
import {
  parseSearchResults,
  SearchResultTypes,
} from "../types/SearchResultTypes";
import { parseTwitterThread } from "../types/TweetDetailConversationTypes";

interface TweetStoreState {
  // tweets queue
  queuedTweets: PreFlightTweet[];
  addTweetToQueue: (tweet: PreFlightTweet) => void;
  removeTweetFromQueue: (id: string) => void;
  // campaign action bar
  campaign: string;
  setCampaign: (name: string) => void;
  // prompt action bar
  prompt: string;
  setPrompt: (prompt: string) => void;
  // quote action bar
  quoteSettings: { checked: boolean; tags: string[] };
  setQuoteSettings: (settings: { checked: boolean; tags: string[] }) => void;
  // mention action bar
  mentionSettings: { checked: boolean; tags: string[] };
  setMentionSettings: (settings: { checked: boolean; tags: string[] }) => void;
  // New agent settings
  agentSettings: {
    checked: boolean;
    selectedAgent: { agent_uuid: string; name: string };
  };
  setAgentSettings: (settings: {
    checked: boolean;
    selectedAgent: { agent_uuid: string; name: string };
  }) => void;
}

export interface PreFlightTweet {
  id: string;
  tweet_text: string;
  reply_to_url: string;
  campaign: string;
  internal_notes: string;
  agent_uuid?: string;
  agent_name?: string;
}

export const useTweetStore = create<TweetStoreState>((set) => ({
  // quote action bar
  queuedTweets: [],
  addTweetToQueue: (t) => {
    console.log("preflight", t);
    set((state) => ({ queuedTweets: state.queuedTweets.concat([t]) }));
  },
  removeTweetFromQueue: (tid) =>
    set((state) => ({
      queuedTweets: state.queuedTweets.filter((q) => q.id !== tid),
    })),
  // campaign action bar
  campaign: "",
  setCampaign: (campaign) => {
    set({ campaign });
    localStorage.setItem("campaign", campaign);
  },
  // prompt action bar
  prompt: "",
  setPrompt: (prompt) => {
    set({ prompt });
    localStorage.setItem("prompt", prompt);
  },
  // quote action bar
  quoteSettings: { checked: false, tags: [] },
  setQuoteSettings: (quoteSettings) => {
    set({ quoteSettings });
    localStorage.setItem("quoteSettings", JSON.stringify(quoteSettings));
  },
  // mention action bar
  mentionSettings: { checked: false, tags: [] },
  setMentionSettings: (mentionSettings) => {
    set({ mentionSettings });
    localStorage.setItem("mentionSettings", JSON.stringify(mentionSettings));
  },
  // New agent settings
  agentSettings: {
    checked: false,
    selectedAgent: { agent_uuid: "random_public", name: "Random Public" },
  },
  setAgentSettings: (agentSettings) => {
    set({ agentSettings });
    localStorage.setItem("agentSettings", JSON.stringify(agentSettings));
  },
}));

export interface GeneratedTweet {
  entryID: string;
  optionID: string;
  tweet_text: string;
  quoted_url: string;
  quoted_text: string;
  quoted_username: string;
}

export const generateBlankTweetsWebook = async ({
  token,
  prompt,
  campaign,
  tweets,
}: {
  token: string;
  prompt: string;
  campaign: string;
  tweets: {
    entryID: string;
    should_quote: boolean;
    quote_tags: string;
    original_tweet_text?: string;
    should_mention: boolean;
    mention_tags: string;
  }[];
}): Promise<GeneratedTweet[][]> => {
  const payload = {
    prompt,
    campaign,
    tweets,
  };
  const body = {
    route: "POST/generate_tweets",
    token: token,
    payload,
  };
  try {
    const response = await fetch(
      "https://api.legions.bot/api/w/run-sybil/jobs/run_wait_result/f/f/runsybil/rest_api_runsybil",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer mXkec9NMmJV4gBE0xVUnzVlrUZLiVXfD",
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user profile");
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error generating tweets:", error);
    return [];
  }
};

export const fetchTwitterByList = async ({
  token,
  payload,
}: {
  token: string;
  payload: {
    listID: string;
    count: number;
    cursor: string | undefined;
  };
}): Promise<{ cursor: string | undefined; tweets: TweetPreview[] }> => {
  const body = {
    route: "GET/twitter_list",
    token: token,
    payload,
  };
  try {
    const response = await fetch(
      "https://api.legions.bot/api/w/run-sybil/jobs/run_wait_result/f/f/runsybil/rest_api_runsybil",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer mXkec9NMmJV4gBE0xVUnzVlrUZLiVXfD",
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch twitter list");
    }

    const data: ListTimelineType = await response.json();
    console.log(data);
    const parsed = parseListTimeline(data);
    return parsed;
  } catch (error) {
    console.error("Error generating tweets:", error);
    return { cursor: undefined, tweets: [] };
  }
};

export const fetchTwitterBySearch = async ({
  token,
  payload,
}: {
  token: string;
  payload: {
    query: string;
    count?: number;
    cursor?: string;
    type?: string;
    safe_search?: boolean;
  };
}): Promise<any> => {
  // Promise<{ cursor: string | undefined; tweets: TweetPreview[] }>
  const body = {
    route: "GET/twitter_search",
    token: token,
    payload,
  };
  try {
    const response = await fetch(
      "https://api.legions.bot/api/w/run-sybil/jobs/run_wait_result/f/f/runsybil/rest_api_runsybil",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer mXkec9NMmJV4gBE0xVUnzVlrUZLiVXfD",
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch twitter list");
    }

    const data: SearchResultTypes = await response.json();
    const parsed = parseSearchResults(data);
    return parsed;
  } catch (error) {
    console.error("Error generating tweets:", error);
    return { cursor: undefined, tweets: [] };
  }
};

export const fetchTwitterByThread = async ({
  token,
  payload,
}: {
  token: string;
  payload: {
    tweetID: string;
    cursor?: string;
  };
}): Promise<{ cursor: string | undefined; tweets: TweetPreview[] }> => {
  const body = {
    route: "GET/twitter_thread",
    token: token,
    payload,
  };
  try {
    const response = await fetch(
      "https://api.legions.bot/api/w/run-sybil/jobs/run_wait_result/f/f/runsybil/rest_api_runsybil",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer mXkec9NMmJV4gBE0xVUnzVlrUZLiVXfD",
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch twitter list");
    }

    const data = await response.json();
    console.log(data);
    const parsed = parseTwitterThread(data);
    console.log(parsed);
    return parsed;
  } catch (error) {
    console.error("Error generating tweets:", error);
    return { cursor: undefined, tweets: [] };
  }
};

export const saveTweetsToCampaign = async ({
  token,
  payload,
}: {
  token: string;
  payload: {
    tweets: {
      entryID: string;
      tweet_text: string;
      reply_to_url: string;
      campaign: string;
      internal_notes: string;
      agent?: string;
    }[];
  };
}): Promise<string[]> => {
  const body = {
    route: "POST/save_to_campaign",
    token: token,
    payload,
  };
  try {
    const response = await fetch(
      "https://api.legions.bot/api/w/run-sybil/jobs/run_wait_result/f/f/runsybil/rest_api_runsybil",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer mXkec9NMmJV4gBE0xVUnzVlrUZLiVXfD",
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch twitter list");
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error generating tweets:", error);
    return [];
  }
};
