import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import {
  Row,
  Col,
  Button,
  Upload,
  Input,
  Space,
  Popover,
  Dropdown,
  Select,
  notification,
  Avatar,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  DeleteOutlined,
  FormOutlined,
  LeftOutlined,
  LoadingOutlined,
  MoreOutlined,
  RetweetOutlined,
  RightOutlined,
  SaveOutlined,
  UploadOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import * as XLSX from "xlsx";
import { RcFile } from "antd/es/upload";
import StickyActionBar from "../components/StickyActionBar";
import {
  generateBlankTweetsWebook,
  useTweetStore,
} from "../hooks/useTweetStore";
import { useAuth } from "../hooks/useAuth";
import { useShallow } from "zustand/react/shallow";
import QuickTweet from "../components/QuickTweet";
import {
  Agent,
  getRandomFromAgentsSet,
  useAgentStore,
} from "../hooks/useAgentStore";

const { TextArea } = Input;

interface Tweet {
  id: string;
  tweet_text: string;
  reply_to_url: string;
  campaign: string;
  internal_notes: string;
  locked: boolean;
  loading?: boolean;
  agent_uuid?: string;
  agent_name?: string;
}

interface EditableCellProps {
  value: string;
  onChange: (value: string) => void;
  onFocus: () => void;
  disabled: boolean;
}

const EditableCell: React.FC<EditableCellProps> = React.memo(
  ({ value, onChange, onFocus, disabled }) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const adjustHeight = useCallback(() => {
      const textArea = textAreaRef.current;
      if (textArea && textArea.style) {
        textArea.style.height = "auto";
        textArea.style.height = `${textArea.scrollHeight}px`;
      }
    }, []);

    useEffect(() => {
      adjustHeight();
    }, [value, adjustHeight]);

    return (
      <TextArea
        ref={textAreaRef}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.value);
          adjustHeight();
        }}
        onFocus={onFocus}
        autoSize={{ minRows: 2, maxRows: 6 }}
        style={{
          width: "100%",
          resize: "none",
          border: "none",
          padding: "8px",
          height: "100%",
        }}
      />
    );
  }
);

const WriteStrategy: React.FC = () => {
  const [tweets, setTweets] = useState<Tweet[]>([]);
  const [focusedCell, setFocusedCell] = useState<{
    id: string;
    field: keyof Tweet;
  } | null>(null);
  const [isGeneratingAll, setIsGeneratingAll] = useState(false);
  const [headerInputs, setHeaderInputs] = useState({
    reply_to_url: "",
    campaign: "",
    internal_notes: "",
    tweet_text: "",
  });
  const { apiKey } = useAuth();
  const {
    prompt,
    quoteSettings,
    campaign,
    addTweetToQueue,
    removeTweetFromQueue,
    mentionSettings,
    agentSettings,
  } = useTweetStore(
    useShallow((state) => ({
      prompt: state.prompt,
      quoteSettings: state.quoteSettings,
      campaign: state.campaign,
      addTweetToQueue: state.addTweetToQueue,
      removeTweetFromQueue: state.removeTweetFromQueue,
      mentionSettings: state.mentionSettings,
      agentSettings: state.agentSettings,
    }))
  );
  const { agents } = useAgentStore(
    useShallow((state) => ({
      agents: state.agents,
    }))
  );
  const [api, contextHolder] = notification.useNotification();
  const [loadingGenerateMap, setLoadingGenerateMap] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    // Initialize with 20 empty rows
    const initialTweets = Array(20)
      .fill(null)
      .map((_, index) => ({
        id: uuidv4(),
        tweet_text: "",
        reply_to_url: "",
        campaign: "",
        internal_notes: "",
        locked: false,
      }));
    setTweets(initialTweets);
  }, []);

  const handleUpload = useCallback((file: RcFile) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      const tweetsWithIds = (json as Partial<Tweet>[]).map((tweet, index) => ({
        id: uuidv4(),
        tweet_text: tweet.tweet_text || "",
        reply_to_url: tweet.reply_to_url || "",
        campaign: tweet.campaign || "",
        internal_notes: tweet.internal_notes || "",
        locked: false,
      }));
      setTweets(tweetsWithIds);
    };
    reader.readAsArrayBuffer(file);
    return false;
  }, []);

  const saveTweet = (tweet: Tweet) => {
    if (!tweet.tweet_text.trim()) return;
    setTweets((prev) =>
      prev.map((t) =>
        t.id === tweet.id && tweet.tweet_text.trim() !== ""
          ? { ...t, locked: true }
          : t
      )
    );

    addTweetToQueue({
      id: tweet.id,
      tweet_text: tweet.tweet_text,
      reply_to_url: tweet.reply_to_url,
      campaign,
      internal_notes: tweet.internal_notes,
      agent_uuid: tweet.agent_uuid,
      agent_name: tweet.agent_name,
    });
  };
  const unlockTweet = (tweet: Tweet) => {
    setTweets((prev) =>
      prev.map((t) => (t.id === tweet.id ? { ...t, locked: false } : t))
    );
    removeTweetFromQueue(tweet.id);
  };
  const removeTweet = (id: string) => {
    setTweets((prev) => prev.filter((tweet) => tweet.id !== id));
  };

  const handleInputChange = useCallback(
    (id: string, field: keyof Tweet, value: string) => {
      setTweets((prev) =>
        prev.map((tweet) =>
          tweet.id === id ? { ...tweet, [field]: value } : tweet
        )
      );
    },
    []
  );

  const handleHeaderInputChange = useCallback(
    (field: keyof typeof headerInputs, value: string) => {
      setHeaderInputs((prev) => ({ ...prev, [field]: value }));
      setTweets((prev) =>
        prev.map((tweet) => ({
          ...tweet,
          [field]: tweet.locked ? tweet[field] : value,
        }))
      );
    },
    []
  );

  const addRow = useCallback(() => {
    setTweets((prev) => [
      ...prev,
      {
        id: uuidv4(),
        tweet_text: "",
        reply_to_url: headerInputs.reply_to_url,
        campaign: headerInputs.campaign,
        internal_notes: headerInputs.internal_notes,
        locked: false,
      },
    ]);
  }, [tweets.length, headerInputs]);

  const addManyRows = useCallback(
    (count: number) => {
      setTweets((prev) => {
        const newRows = Array(count)
          .fill(null)
          .map((_, index) => ({
            id: uuidv4(),
            tweet_text: "",
            reply_to_url: headerInputs.reply_to_url,
            campaign: headerInputs.campaign,
            internal_notes: headerInputs.internal_notes,
            locked: false,
          }));
        return [...prev, ...newRows];
      });
    },
    [tweets.length, headerInputs]
  );

  const handleCellFocus = useCallback((id: string, field: keyof Tweet) => {
    setFocusedCell({ id, field });
  }, []);

  const generateTweet = async (id: string, agent?: Agent) => {
    if (apiKey) {
      setLoadingGenerateMap((prev) => ({ ...prev, [id]: true }));
      setTweets((prev) =>
        prev.map((tweet) =>
          tweet.id === id
            ? {
                ...tweet,
                loading: true,
                locked: true,
                tweet_text: "Generating...",
              }
            : tweet
        )
      );

      const generatedTweets = await generateBlankTweetsWebook({
        token: apiKey,
        prompt,
        campaign,
        tweets: [
          {
            entryID: id,
            should_quote: quoteSettings.checked,
            quote_tags: quoteSettings.tags.join(","),
            should_mention: mentionSettings.checked,
            mention_tags: mentionSettings.tags.join(","),
          },
        ],
      });
      setTweets((prev) =>
        prev.map((tweet) => {
          const matchedTweet = generatedTweets.find(
            (_tset) => _tset[0].entryID === tweet.id
          );
          if (matchedTweet) {
            const t = {
              tweet_text: matchedTweet[0].tweet_text,
              reply_to_url: tweet.reply_to_url || "",
              campaign: campaign || "",
              loading: false,
              locked: false,
              internal_notes: tweet.internal_notes || "",
              id: tweet.id,
            };
            if (agent) {
              // @ts-ignore
              t.agent_uuid = agent.agent_uuid || "";
              // @ts-ignore
              t.agent_name = agent.name || "";
            }
            return t;
          } else {
            return tweet;
          }
        })
      );
      setLoadingGenerateMap((prev) => ({ ...prev, [id]: false }));
    }
  };

  const bulkGenerateTweet = async (ids: string[]) => {
    if (apiKey) {
      for (const id of ids) {
        setLoadingGenerateMap((prev) => ({ ...prev, [id]: true }));
      }
      setTweets((prev) =>
        prev.map((tweet) =>
          ids.includes(tweet.id)
            ? {
                ...tweet,
                loading: true,
                locked: true,
                tweet_text: "Generating...",
              }
            : tweet
        )
      );

      const generatedTweets = await generateBlankTweetsWebook({
        token: apiKey,
        prompt,
        campaign,
        tweets: ids.map((id) => ({
          entryID: id,
          should_quote: quoteSettings.checked,
          quote_tags: quoteSettings.tags.join(","),
          should_mention: mentionSettings.checked,
          mention_tags: mentionSettings.tags.join(","),
        })),
      });
      setTweets((prev) =>
        prev.map((tweet) => {
          const matchedTweet = generatedTweets.find(
            (_tset) => _tset[0].entryID === tweet.id
          );
          if (matchedTweet) {
            return {
              tweet_text: matchedTweet[0].tweet_text,
              reply_to_url: tweet.reply_to_url || "",
              campaign: campaign || "",
              loading: false,
              locked: false,
              internal_notes: tweet.internal_notes || "",
              id: tweet.id,
            };
          } else {
            return tweet;
          }
        })
      );
      for (const id of ids) {
        setLoadingGenerateMap((prev) => ({ ...prev, [id]: false }));
      }
    }
  };

  const generateAll = async () => {
    setIsGeneratingAll(true);
    const batchSize = 20;
    const totalBatches = Math.ceil(tweets.length / batchSize);

    for (let i = 0; i < totalBatches; i++) {
      const startIndex = i * batchSize;
      const endIndex = Math.min((i + 1) * batchSize, tweets.length);
      const batchIds = tweets
        .slice(startIndex, endIndex)
        .map((tweet) => tweet.id);

      await bulkGenerateTweet(batchIds);
    }

    setIsGeneratingAll(false);
    notification.success({
      message: "Generation Complete",
      description: "All tweets have been generated successfully",
      duration: 30,
    });
  };

  const saveAll = () => {
    for (const tweet of tweets) {
      if (!tweet.locked) {
        saveTweet(tweet);
      }
    }
    notification.success({
      message: "Saved All",
      description: "All eligible tweets have been saved",
    });
  };

  const agentOptions = useMemo(() => {
    const options = [
      {
        agent_uuid: "random_public",
        name: "Random Public",
        avatar_url:
          "https://play-lh.googleusercontent.com/jKPgqX4JBR97fgfOYcxtPLHwNhnfdwkxOoq66cFNfc0jTqZg_ZAxKsGxSsYfgyWobAUs=w240-h480-rw",
      },
    ];

    agents.forEach((agent) => {
      options.push({
        agent_uuid: agent.agent_uuid,
        name: agent.name,
        avatar_url: agent.avatar_url,
      });
    });

    return options;
  }, [agents]);

  return (
    <div style={{ padding: "0px 10px" }}>
      {contextHolder}
      <div>
        <h1>Blank Writer</h1>
        <p>Generate many tweets</p>
      </div>
      <Row
        gutter={[5, 5]}
        justify="space-between"
        style={{ marginBottom: "16px", marginLeft: "30px" }}
      >
        <Col>
          <Upload
            beforeUpload={handleUpload}
            showUploadList={false}
            accept=".xlsx,.xls"
          >
            <Button icon={<UploadOutlined />}>Upload Spreadsheet (XLSX)</Button>
          </Upload>
        </Col>
        <div style={{}}>
          <Button
            onClick={generateAll}
            loading={isGeneratingAll}
            style={{ borderRadius: "5px 0px 0px 5px" }}
          >
            Generate All
          </Button>

          <Button onClick={saveAll} style={{ borderRadius: "0px 5px 5px 0px" }}>
            Save All
          </Button>
        </div>
      </Row>
      <div style={{ overflowX: "auto" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            backgroundColor: "white",
          }}
        >
          <thead>
            <tr>
              <th>{` # `}</th>
              <th
                style={{
                  width: "40%",
                  padding: "8px",
                  border: "1px solid #d9d9d9",
                  minWidth: "200px",
                  textAlign: "left",
                }}
              >
                tweet_text
                <Input
                  value={headerInputs.tweet_text}
                  onChange={(e) =>
                    handleHeaderInputChange("tweet_text", e.target.value)
                  }
                  style={{ marginTop: "4px" }}
                  placeholder="Set for all rows"
                />
              </th>
              <th
                style={{
                  width: "20%",
                  padding: "8px",
                  border: "1px solid #d9d9d9",
                  minWidth: "150px",
                  textAlign: "left",
                }}
              >
                reply_to_url
                <Input
                  value={headerInputs.reply_to_url}
                  onChange={(e) =>
                    handleHeaderInputChange("reply_to_url", e.target.value)
                  }
                  style={{ marginTop: "4px" }}
                  placeholder="Set for all rows"
                />
              </th>
              <th
                style={{
                  width: "20%",
                  padding: "8px",
                  border: "1px solid #d9d9d9",
                  minWidth: "150px",
                  textAlign: "left",
                }}
              >
                campaign
                <Input
                  value={headerInputs.campaign}
                  onChange={(e) =>
                    handleHeaderInputChange("campaign", e.target.value)
                  }
                  style={{ marginTop: "4px" }}
                  placeholder="Set for all rows"
                />
              </th>
              {/* <th
                style={{
                  width: "20%",
                  padding: "8px",
                  border: "1px solid #d9d9d9",
                  minWidth: "150px",
                  textAlign: "left",
                }}
              >
                internal_notes
                <Input
                  value={headerInputs.internal_notes}
                  onChange={(e) =>
                    handleHeaderInputChange("internal_notes", e.target.value)
                  }
                  style={{ marginTop: "4px" }}
                  placeholder="Set for all rows"
                />
              </th> */}
              <th
                style={{
                  width: "15%",
                  padding: "8px",
                  border: "1px solid #d9d9d9",
                  minWidth: "150px",
                  textAlign: "left",
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {tweets.map((tweet, idx) => (
              <BlankRow
                tweet={tweet}
                idx={idx}
                generateTweet={generateTweet}
                unlockTweet={unlockTweet}
                saveTweet={saveTweet}
                removeTweet={removeTweet}
                agents={agents}
                loadingGenerateMap={loadingGenerateMap}
                handleInputChange={handleInputChange}
                handleCellFocus={handleCellFocus}
                agentOptions={agentOptions}
                defaultAgent={agentSettings.selectedAgent}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Button onClick={addRow} style={{ marginTop: "16px" }}>
          Add Row
        </Button>
        <Button onClick={() => addManyRows(20)} style={{ marginTop: "16px" }}>
          +20 Rows
        </Button>
      </div>
      <br />
      <br />
      <br />
      <br />
      <QuickTweet />
    </div>
  );
};

export default WriteStrategy;

const BlankRow = ({
  tweet,
  idx,
  agents,
  generateTweet,
  unlockTweet,
  saveTweet,
  removeTweet,
  loadingGenerateMap,
  handleInputChange,
  handleCellFocus,
  agentOptions,
  defaultAgent,
}: {
  tweet: Tweet;
  idx: number;
  generateTweet: (id: string, agent?: Agent) => void;
  unlockTweet: (tweet: Tweet) => void;
  saveTweet: (tweet: Tweet) => void;
  removeTweet: (id: string) => void;
  loadingGenerateMap: Record<string, boolean>;
  handleInputChange: (id: string, field: keyof Tweet, value: string) => void;
  handleCellFocus: (id: string, field: keyof Tweet) => void;
  agents: Agent[];
  agentOptions: { agent_uuid: string; name: string; avatar_url?: string }[];
  defaultAgent: { agent_uuid: string; name: string };
}) => {
  const [selectedAgent, setSelectedAgent] = useState<Agent>();

  useEffect(() => {
    const agent = getRandomFromAgentsSet(agents, defaultAgent.agent_uuid);
    setSelectedAgent(agent);
    handleInputChange(tweet.id, "agent_uuid", agent.agent_uuid);
    handleInputChange(tweet.id, "agent_name", agent.agent_name);
  }, []);
  const handleAgentChange = (value: string) => {
    const selectedOption = agentOptions.find(
      (option) => option.agent_uuid === value
    );
    if (selectedOption) {
      if (selectedOption.agent_uuid === "random_public") {
        // For public, team, or team-specific options, we create a pseudo-Agent
        setSelectedAgent({
          agent_uuid: selectedOption.agent_uuid,
          avatar_url: selectedOption.avatar_url || "",
          name: selectedOption.name,
          internal_notes: "",
          twitter_url: "",
          twitter_username: "",
          teams: [],
          status: "active",
        });
      } else {
        // For individual agents, we can directly set the Agent object
        setSelectedAgent(selectedOption as Agent);
      }
      handleInputChange(tweet.id, "agent_uuid", selectedOption.agent_uuid);
      handleInputChange(tweet.id, "agent_name", selectedOption.name);
    }
  };

  const filterOption = (
    input: string,
    option?: { children: string; value: string }
  ) => {
    return (
      (option?.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
      0
    );
  };

  return (
    <tr key={tweet.id}>
      {(
        [
          "#",
          "tweet_text",
          "reply_to_url",
          "campaign",
          // "internal_notes",
          "actions",
        ] as const
      ).map((field) => {
        if (field === "#") {
          return (
            <td key={`#-${idx + 1}`} style={{ minWidth: "30px" }}>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "generate",
                      label: (
                        <div
                          onClick={() => generateTweet(tweet.id, selectedAgent)}
                        >
                          <RetweetOutlined style={{ marginRight: "10px" }} />
                          <span>Generate</span>
                        </div>
                      ),
                    },
                    {
                      key: "agent",
                      label: (
                        <Popover
                          content={
                            <Select
                              disabled
                              defaultValue="Random"
                              style={{ width: "100%" }}
                            >
                              <Select.Option value="Random">
                                Random
                              </Select.Option>
                            </Select>
                          }
                          title="Agent"
                          trigger="click"
                        >
                          <UserSwitchOutlined style={{ marginRight: "10px" }} />
                          {`Agent`}
                        </Popover>
                      ),
                    },
                    {
                      key: "editsave",
                      label: tweet.locked ? (
                        <div onClick={() => unlockTweet(tweet)}>
                          <SaveOutlined style={{ marginRight: "10px" }} />
                          <span>Edit</span>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            saveTweet({
                              ...tweet,
                              agent_uuid: selectedAgent?.agent_uuid,
                              agent_name: selectedAgent?.name,
                            });
                          }}
                        >
                          <SaveOutlined style={{ marginRight: "10px" }} />
                          <span>Save</span>
                        </div>
                      ),
                    },
                    {
                      key: "remove",
                      label: (
                        <div onClick={() => removeTweet(tweet.id)}>
                          <DeleteOutlined style={{ marginRight: "10px" }} />
                          <span>Remove</span>
                        </div>
                      ),
                    },
                  ],
                }}
                placement="bottomRight"
              >
                <Button
                  ghost
                  style={{
                    border: "1px dotted rgba(0,0,0,0.1)",
                    color: "gray",
                  }}
                >
                  {tweet.loading ? <LoadingOutlined /> : <span>{idx + 1}</span>}
                </Button>
              </Dropdown>
            </td>
          );
        }
        if (field === "actions") {
          return (
            <td
              key={`${tweet.id}-actions`}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 10px",
                height: "100%",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <div style={{ position: "absolute", marginLeft: "-210px" }}>
                <Popover
                  content={
                    <div style={{ width: 200 }}>
                      <Select
                        style={{ width: "100%" }}
                        value={tweet.agent_uuid || "random_public"}
                        onChange={handleAgentChange}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        placeholder="Search for an agent"
                      >
                        {agentOptions.map((option, i) => (
                          <Select.Option
                            key={option.agent_uuid || i}
                            value={option.agent_uuid || ""}
                          >
                            <Avatar src={option.avatar_url} />
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  }
                  title="Select Agent"
                  trigger="click"
                >
                  <Avatar
                    size="large"
                    src={
                      selectedAgent?.avatar_url ||
                      "https://play-lh.googleusercontent.com/jKPgqX4JBR97fgfOYcxtPLHwNhnfdwkxOoq66cFNfc0jTqZg_ZAxKsGxSsYfgyWobAUs=w240-h480-rw"
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {selectedAgent?.name?.[0] || "A"}
                  </Avatar>
                </Popover>
              </div>
              <Button
                onClick={() => generateTweet(tweet.id)}
                loading={loadingGenerateMap[tweet.id]}
                style={{ borderRadius: "5px 0px 0px 5px" }}
                disabled={tweet.locked}
              >
                Generate
              </Button>
              {tweet.locked ? (
                <Button
                  disabled={!tweet.locked}
                  onClick={() => unlockTweet(tweet)}
                  style={{ borderRadius: "0px 5px 5px 0px" }}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  disabled={tweet.locked}
                  onClick={() =>
                    saveTweet({
                      ...tweet,
                      agent_uuid: selectedAgent?.agent_uuid,
                      agent_name: selectedAgent?.name,
                    })
                  }
                  style={{ borderRadius: "0px 5px 5px 0px" }}
                >
                  Save
                </Button>
              )}
            </td>
          );
        }
        return (
          <td
            key={`${tweet.id}-${field}`}
            style={{ border: "1px solid #d9d9d9", padding: 0 }}
          >
            <EditableCell
              disabled={tweet.locked}
              value={tweet[field]}
              onChange={(value) => handleInputChange(tweet.id, field, value)}
              onFocus={() => handleCellFocus(tweet.id, field)}
            />
          </td>
        );
      })}
    </tr>
  );
};
