import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import TablePickerView from "../components/TablePickerView";
import { useAuth } from "../hooks/useAuth";

const { TabPane } = Tabs;

const DownloadsPage: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { myProfile } = useAuth();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  if (!isLoaded || !myProfile) {
    return null; // or a loading indicator
  }
  return (
    <>
      <br />
      <br />
      <br />
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Followers" key="1">
          <TablePickerView
            title="Followers"
            cookieSlug={`nocodb_followers_view_${myProfile.telegram_username}`}
            description={
              <div style={{ lineHeight: "1.3rem" }}>
                <span>Your downloaded followers appear here</span>
                <br />
                <span>
                  Telegram{" "}
                  <a href="https://t.me/TweetMob_bot" target="_blank">
                    @TweetMob_bot
                  </a>
                  {` `}
                  <code
                    style={{ backgroundColor: "#e1e4ff" }}
                  >{`/download`}</code>
                  {" to add more."}
                </span>
                <br />
                <span>You can tag these people in mentions</span>
              </div>
            }
          />
        </TabPane>
        <TabPane tab="Downloads" key="2">
          <TablePickerView
            title="Download Jobs"
            cookieSlug={`nocodb_webscraping_view_${myProfile.telegram_username}`}
            description={
              <div style={{ lineHeight: "1.3rem" }}>
                <span>
                  Your download jobs report their status here. View only, do not
                  edit.
                </span>
                <br />
                <span>
                  Telegram{" "}
                  <a href="https://t.me/TweetMob_bot" target="_blank">
                    @TweetMob_bot
                  </a>
                  {` `}
                  <code
                    style={{ backgroundColor: "#e1e4ff" }}
                  >{`/download`}</code>
                  {` or `}
                  <code
                    style={{ backgroundColor: "#e1e4ff" }}
                  >{`/continue`}</code>
                  {" to add more."}
                </span>
                <br />
              </div>
            }
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default DownloadsPage;
