import React from "react";

export const TABLE_PICKER_VIEW_HELP_TEXT = (
  <span>
    {`Enter your public table view url - `}
    <a href="https://tweetmob.app" target="_blank">
      watch tutorial
    </a>
  </span>
);
