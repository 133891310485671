import React from "react";
import { Row, Col, Typography } from "antd";
import TweetCard, { TweetPreview } from "./TweetCard";
import SpriteSpreadsheetEditor from "./SpriteSpreadsheetEditor";
import { useTweetStore } from "../hooks/useTweetStore";

const { Title } = Typography;

interface TweetResponderProps {
  tweet: TweetPreview;
  generateButtonID: string;
  saveButtonID: string;
}

const TweetResponder: React.FC<TweetResponderProps> = ({
  tweet,
  generateButtonID,
  saveButtonID,
}) => {
  const campaign = useTweetStore((state) => state.campaign);
  return (
    <Row
      gutter={[16, 16]}
      style={{
        width: "100%",
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ maxWidth: "500px", width: "100%" }}>
          <TweetCard tweet={tweet} />
        </div>
      </Col>
      <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "100%", maxWidth: "500px" }}>
          <SpriteSpreadsheetEditor
            tweetText={tweet.text}
            replyToUrl={tweet.url}
            campaign={campaign}
            generateButtonID={generateButtonID}
            saveButtonID={saveButtonID}
          />
        </div>
      </Col>
    </Row>
  );
};

export default TweetResponder;
