import {
  Button,
  Divider,
  Input,
  message,
  notification,
  Popover,
  Space,
  Typography,
} from "antd";
import StickyActionBar from "../components/StickyActionBar";
import { useCallback, useMemo, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { fetchTwitterByThread } from "../hooks/useTweetStore";
import { useAuth } from "../hooks/useAuth";
import React from "react";
import TweetResponder from "../components/TweetResponder";
import QuickTweet from "../components/QuickTweet";

const { Text } = Typography;

const generateButtonID = "thread-strategy-generate-button";
const saveButtonID = "thread-strategy-save-button";

const RaidStrategy = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [tweets, setTweets] = useState<any[]>([]);
  const [cursor, setCursor] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { apiKey } = useAuth();
  const [api, contextHolder] = notification.useNotification();
  const [isGeneratingAll, setIsGeneratingAll] = useState(false);

  const handleSearch = useCallback(async () => {
    if (!apiKey) return;
    setIsLoading(true);
    const { cursor: returnedCursor, tweets: returnedTweets } =
      await fetchTwitterByThread({
        token: apiKey,
        payload: {
          tweetID: searchQuery.split("?")[0].split("/").pop() || "",
          cursor,
        },
      });
    setCursor(returnedCursor);
    setTweets((prev) => [...prev, ...returnedTweets]);
    setIsLoading(false);
    message.success(`Added ${returnedTweets.length} replies to bottom of page`);
  }, [searchQuery, cursor]);

  const tweetCount = useMemo(() => tweets.length, [tweets]);
  const renderOriginal = useMemo(
    () =>
      tweets[0] && (
        <React.Fragment key={`${tweets[0].url}-original`}>
          <TweetResponder
            tweet={tweets[0]}
            generateButtonID={generateButtonID}
            saveButtonID={saveButtonID}
          />
        </React.Fragment>
      ),
    [tweets[0]]
  );
  const renderTweets = useMemo(
    () =>
      tweets.slice(1).map((tweet, index) => (
        <React.Fragment key={`${tweet.url}-${index}`}>
          <TweetResponder
            tweet={tweet}
            generateButtonID={generateButtonID}
            saveButtonID={saveButtonID}
          />
          {index < tweets.length - 1 && <Divider />}
        </React.Fragment>
      )),
    [tweets]
  );

  const generateAll = async () => {
    setIsGeneratingAll(true);
    const buttons = document.querySelectorAll(`#${generateButtonID}`);
    console.log("Buttons", buttons);
    let count = 0;
    // @ts-ignore
    for (const button of buttons) {
      count += 1;
      button.click();
      message.success(`Generated reply ${count}/${buttons.length}`);
      // sleep 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
    setIsGeneratingAll(false);
    notification.success({
      message: "Successfully Generated",
      description: `Generated ${count} replies`,
      duration: 30,
    });
  };

  const saveAll = async () => {
    const buttons = document.querySelectorAll(`#${saveButtonID}`);
    // @ts-ignore
    for (const button of buttons) {
      button.click();
    }
    notification.success({
      message: "Successfully Saved",
      description: `Saved all replies`,
    });
  };

  const handleBulkLoad = async () => {
    // run handleSearch 5 times in sequential sync order, or until no more cursor
    // with a delay of 2 seconds in between
    let count = 0;
    while (count < 5 && cursor) {
      await handleSearch();
      count += 1;
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {contextHolder}
      <div>
        <h1>Raid Strategy</h1>
        <p>Target thread and respond to existing replies</p>
      </div>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div style={{ margin: "0px 10px" }}>
          <Input.Search
            placeholder="Twitter Thread URL"
            allowClear
            size="large"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onSearch={handleSearch}
            style={{ maxWidth: "600px" }}
            loading={isLoading}
            prefix={
              <Popover
                content={
                  <span>
                    Learn how to target Twitter Threads.{" "}
                    <a
                      href="https://developer.twitter.com/en/docs/twitter-api/tweets/search/integrate/build-a-query"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      More info
                    </a>
                  </span>
                }
                title="Targeting Threads"
              >
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Popover>
            }
          />
          <Text type="secondary" style={{ display: "block", marginTop: "8px" }}>
            Showing {tweetCount} replies
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              loading={isGeneratingAll}
              onClick={generateAll}
              style={{ borderRadius: "5px 0px 0px 5px" }}
              disabled={tweets.length === 0}
            >
              Generate All
            </Button>

            <Button
              onClick={saveAll}
              style={{ borderRadius: "0px 5px 5px 0px" }}
              disabled={tweets.length === 0}
            >
              Save All
            </Button>
          </div>
        </div>
        <Divider>Original Tweet</Divider>
        {renderOriginal}
        <Divider>Replies</Divider>
        {renderTweets}
        <div style={{ marginBottom: "80px" }}>
          <Button
            disabled={tweets.length === 0}
            onClick={handleSearch}
            loading={isLoading}
          >
            Load More
          </Button>
          <Button
            disabled={tweets.length === 0}
            onClick={handleBulkLoad}
            loading={isLoading}
          >
            Load 10x
          </Button>
        </div>
      </Space>
      <QuickTweet />
    </div>
  );
};

export default RaidStrategy;
