import { TweetPreview } from "../components/TweetCard";

export interface SearchResultTypes {
  data: Data;
}

export function parseSearchResults(input: SearchResultTypes): {
  cursor: string;
  tweets: TweetPreview[];
} {
  const tweets: TweetPreview[] = [];
  let cursor = "";

  const instructions =
    input.data.search_by_raw_query.search_timeline.timeline.instructions;

  instructions.forEach((instruction) => {
    if (instruction.type === "TimelineAddEntries") {
      instruction.entries.forEach((entry) => {
        if (
          entry.content.entryType === "TimelineTimelineItem" &&
          entry.content.itemContent?.tweet_results?.result
        ) {
          const tweetResult = entry.content.itemContent.tweet_results.result;
          const tweet = convertTweetToPreview(tweetResult);
          if (tweet) tweets.push(tweet);
        } else if (
          entry.content.entryType === "TimelineTimelineCursor" &&
          entry.content.cursorType === "Bottom"
        ) {
          cursor = entry.content.value || "";
        }
      });
    } else if (instruction.type === "TimelineReplaceEntry") {
      // Handle the case for subsequent pagination requests
      if (
        // @ts-ignore
        instruction.entry.content.entryType === "TimelineTimelineCursor" &&
        // @ts-ignore
        instruction.entry.content.cursorType === "Bottom"
      ) {
        // @ts-ignore
        cursor = instruction.entry.content.value || "";
      }
    }
  });

  return { cursor, tweets };
}

function convertTweetToPreview(tweetResult: any): TweetPreview | null {
  if (!tweetResult.legacy) return null;

  const tweet: TweetPreview = {
    url: `https://twitter.com/${tweetResult.core?.user_results.result.legacy.screen_name}/status/${tweetResult.rest_id}`,
    text: tweetResult.legacy.full_text,
    views: parseInt(tweetResult.views?.count || "0"),
    likes: tweetResult.legacy.favorite_count,
    retweets: tweetResult.legacy.retweet_count,
    replies: tweetResult.legacy.reply_count,
    quoteCount: tweetResult.legacy.quote_count,
    author: {
      name: tweetResult.core?.user_results.result.legacy.name,
      screenName: tweetResult.core?.user_results.result.legacy.screen_name,
      profileImageUrl:
        tweetResult.core?.user_results.result.legacy.profile_image_url_https,
      isVerified: tweetResult.core?.user_results.result.legacy.verified,
      url: `https://twitter.com/${tweetResult.core?.user_results.result.legacy.screen_name}`,
    },
    createdAt: tweetResult.legacy.created_at,
    source: tweetResult.source || tweetResult.legacy.source,
  };

  if (tweetResult.legacy.extended_entities?.media) {
    tweet.media = tweetResult.legacy.extended_entities.media.map(
      (media: any) => ({
        mediaUrl: media.media_url_https,
        type: media.type,
      })
    );
  }

  if (tweetResult.quoted_status_result?.result) {
    tweet.quotedTweet = convertTweetToPreview(
      tweetResult.quoted_status_result.result
    );
  }

  return tweet;
}

interface Data {
  search_by_raw_query: Searchbyrawquery;
}
interface Searchbyrawquery {
  search_timeline: Searchtimeline;
}
interface Searchtimeline {
  timeline: Timeline;
}
interface Timeline {
  instructions: Instruction[];
}
interface Instruction {
  type: string;
  entries: Entry[];
}
interface Entry {
  entryId: string;
  sortIndex: string;
  content: Content;
}
interface Content {
  entryType: string;
  __typename: string;
  itemContent?: ItemContent;
  clientEventInfo?: ClientEventInfo;
  value?: string;
  cursorType?: string;
}
interface ClientEventInfo {
  component: string;
  element: string;
  details: Details;
}
interface Details {
  timelinesDetails: TimelinesDetails;
}
interface TimelinesDetails {
  controllerData: string;
}
interface ItemContent {
  itemType: string;
  __typename: string;
  tweet_results: Tweetresults;
  tweetDisplayType: string;
  highlights?: Highlights;
}
interface Highlights {
  textHighlights: TextHighlight[];
}
interface TextHighlight {
  startIndex: number;
  endIndex: number;
}
interface Tweetresults {
  result: Result8;
}
interface Result8 {
  __typename: string;
  rest_id?: string;
  core?: Core;
  unmention_data?: Affiliateshighlightedlabel;
  edit_control?: Editcontrol;
  is_translatable?: boolean;
  views?: Views;
  source?: string;
  legacy?: Legacy2;
  note_tweet?: Notetweet;
  quoted_status_result?: Quotedstatusresult;
  tweet?: Tweet;
  limitedActionResults?: LimitedActionResults;
}
interface LimitedActionResults {
  limited_actions: Limitedaction[];
}
interface Limitedaction {
  action: string;
  prompt: Prompt;
}
interface Prompt {
  __typename: string;
  cta_type: string;
  headline: Headline;
  subtext: Headline;
}
interface Headline {
  text: string;
  entities: any[];
}
interface Tweet {
  rest_id: string;
  core: Core3;
  unmention_data: Affiliateshighlightedlabel;
  edit_control: Editcontrol;
  is_translatable: boolean;
  views: Views2;
  source: string;
  legacy: Legacy7;
}
interface Legacy7 {
  bookmark_count: number;
  bookmarked: boolean;
  created_at: string;
  conversation_control: Conversationcontrol;
  conversation_id_str: string;
  display_text_range: number[];
  entities: Entities6;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  is_quote_status: boolean;
  lang: string;
  quote_count: number;
  reply_count: number;
  retweet_count: number;
  retweeted: boolean;
  user_id_str: string;
  id_str: string;
}
interface Entities6 {
  user_mentions: any[];
  urls: any[];
  hashtags: any[];
  symbols: any[];
}
interface Conversationcontrol {
  policy: string;
  conversation_owner_results: Conversationownerresults;
}
interface Conversationownerresults {
  result: Result7;
}
interface Result7 {
  __typename: string;
  legacy: Legacy6;
}
interface Legacy6 {
  screen_name: string;
}
interface Core3 {
  user_results: Userresults3;
}
interface Userresults3 {
  result: Result6;
}
interface Result6 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy5;
  professional: Professional2;
}
interface Professional2 {
  rest_id: string;
  professional_type: string;
  category: Category[];
}
interface Legacy5 {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities5;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: any[];
  possibly_sensitive: boolean;
  profile_banner_url: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  url: string;
  verified: boolean;
  want_retweets: boolean;
  withheld_in_countries: any[];
}
interface Entities5 {
  description: Description2;
  url: Url2;
}
interface Description2 {
  urls: any[];
}
interface Quotedstatusresult {
  result: Result5;
}
interface Result5 {
  __typename: string;
  rest_id: string;
  core: Core2;
  unmention_data: Affiliateshighlightedlabel;
  edit_control: Editcontrol;
  is_translatable: boolean;
  views: Views2;
  source: string;
  quotedRefResult?: QuotedRefResult;
  legacy: Legacy4;
}
interface Legacy4 {
  bookmark_count: number;
  bookmarked: boolean;
  created_at: string;
  conversation_id_str: string;
  display_text_range: number[];
  entities: Entities4;
  extended_entities: Extendedentities2;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  is_quote_status: boolean;
  lang: string;
  possibly_sensitive: boolean;
  possibly_sensitive_editable: boolean;
  quote_count: number;
  quoted_status_id_str?: string;
  quoted_status_permalink?: Quotedstatuspermalink;
  reply_count: number;
  retweet_count: number;
  retweeted: boolean;
  user_id_str: string;
  id_str: string;
}
interface Extendedentities2 {
  media: Media2[];
}
interface Entities4 {
  media: Media2[];
  user_mentions: any[];
  urls: any[];
  hashtags: any[];
  symbols: any[];
}
interface Media2 {
  display_url: string;
  expanded_url: string;
  id_str: string;
  indices: number[];
  media_key: string;
  media_url_https: string;
  type: string;
  url: string;
  ext_media_availability: Extmediaavailability;
  features: Features;
  sizes: Sizes;
  original_info: Originalinfo2;
  allow_download_status?: Allowdownloadstatus;
}
interface Allowdownloadstatus {
  allow_download: boolean;
}
interface Originalinfo2 {
  height: number;
  width: number;
  focus_rects: Face[];
}
interface QuotedRefResult {
  result: Result4;
}
interface Result4 {
  __typename: string;
  rest_id: string;
}
interface Views2 {
  count: string;
  state: string;
}
interface Core2 {
  user_results: Userresults2;
}
interface Userresults2 {
  result: Result3;
}
interface Result3 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy3;
  professional: Professional;
}
interface Legacy3 {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities3;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: string[];
  possibly_sensitive: boolean;
  profile_banner_url: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  url: string;
  verified: boolean;
  want_retweets: boolean;
  withheld_in_countries: any[];
}
interface Entities3 {
  description: Description;
  url: Url2;
}
interface Notetweet {
  is_expandable: boolean;
  note_tweet_results: Notetweetresults;
}
interface Notetweetresults {
  result: Result2;
}
interface Result2 {
  id: string;
  text: string;
  entity_set: Entityset;
}
interface Entityset {
  user_mentions: any[];
  urls: Url[];
  hashtags: Hashtag[];
  symbols: Hashtag[];
}
interface Legacy2 {
  bookmark_count: number;
  bookmarked: boolean;
  created_at: string;
  conversation_id_str: string;
  display_text_range: number[];
  entities: Entities2;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  in_reply_to_screen_name?: string;
  in_reply_to_status_id_str?: string;
  in_reply_to_user_id_str?: string;
  is_quote_status: boolean;
  lang: string;
  quote_count: number;
  reply_count: number;
  retweet_count: number;
  retweeted: boolean;
  user_id_str: string;
  id_str: string;
  extended_entities?: Extendedentities;
  possibly_sensitive?: boolean;
  possibly_sensitive_editable?: boolean;
  quoted_status_id_str?: string;
  quoted_status_permalink?: Quotedstatuspermalink;
}
interface Quotedstatuspermalink {
  url: string;
  expanded: string;
  display: string;
}
interface Extendedentities {
  media: Media[];
}
interface Entities2 {
  user_mentions: Usermention[];
  urls: Url[];
  hashtags: Hashtag[];
  symbols: Hashtag[];
  media?: Media[];
}
interface Media {
  display_url: string;
  expanded_url: string;
  id_str: string;
  indices: number[];
  media_key: string;
  media_url_https: string;
  type: string;
  url: string;
  ext_media_availability: Extmediaavailability;
  features?: Features;
  sizes: Sizes;
  original_info: Originalinfo;
  video_info?: Videoinfo;
}
interface Videoinfo {
  aspect_ratio: number[];
  variants: Variant[];
}
interface Variant {
  bitrate: number;
  content_type: string;
  url: string;
}
interface Originalinfo {
  height: number;
  width: number;
  focus_rects: Face[];
}
interface Sizes {
  large: Large2;
  medium: Large2;
  small: Large2;
  thumb: Large2;
}
interface Large2 {
  h: number;
  w: number;
  resize: string;
}
interface Features {
  large: Large;
  medium: Large;
  small: Large;
  orig: Large;
}
interface Large {
  faces: Face[];
}
interface Face {
  x: number;
  y: number;
  h: number;
  w: number;
}
interface Extmediaavailability {
  status: string;
}
interface Hashtag {
  indices: number[];
  text: string;
}
interface Usermention {
  id_str: string;
  name: string;
  screen_name: string;
  indices: number[];
}
interface Views {
  count?: string;
  state: string;
}
interface Editcontrol {
  edit_tweet_ids: string[];
  editable_until_msecs: string;
  is_edit_eligible: boolean;
  edits_remaining: string;
}
interface Core {
  user_results: Userresults;
}
interface Userresults {
  result: Result;
}
interface Result {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy;
  professional?: Professional;
  super_follow_eligible?: boolean;
}
interface Professional {
  rest_id: string;
  professional_type: string;
  category: Category[];
}
interface Category {
  id: number;
  name: string;
  icon_name: string;
}
interface Legacy {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: string[];
  possibly_sensitive: boolean;
  profile_banner_url?: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  verified: boolean;
  want_retweets: boolean;
  withheld_in_countries: any[];
  url?: string;
}
interface Entities {
  description: Description;
  url?: Url2;
}
interface Url2 {
  urls: Url[];
}
interface Description {
  urls: Url[];
}
interface Url {
  display_url: string;
  expanded_url: string;
  url: string;
  indices: number[];
}
interface Affiliateshighlightedlabel {}
