import React, { useState, useCallback, useMemo } from "react";
import {
  Input,
  Button,
  Popover,
  Typography,
  Divider,
  Space,
  Row,
  Col,
  Tag,
  Checkbox,
  notification,
  message,
} from "antd";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import {
  sampleTweet_everything,
  sampleTweet_noQuote,
  sampleTweet_onlyQuote,
  sampleTweet_pureText,
  TweetPreview,
} from "../components/TweetCard";
import TweetResponder from "../components/TweetResponder";
import { debounce } from "lodash";
import { useAuth } from "../hooks/useAuth";
import { fetchTwitterByList } from "../hooks/useTweetStore";
import QuickTweet from "../components/QuickTweet";

const { Text } = Typography;

const generateButtonID = "list-strategy-generate-button";
const saveButtonID = "list-strategy-save-button";

export const ListStrategy = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [tweets, setTweets] = useState<TweetPreview[]>([]);
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { apiKey } = useAuth();
  const [api, contextHolder] = notification.useNotification();
  const [isGeneratingAll, setIsGeneratingAll] = useState(false);

  const handleSearch = useCallback(async () => {
    console.log("Searching for:", searchQuery);
    if (!apiKey) return;
    setIsLoading(true);
    const listID = searchQuery.split("/").pop() || "";
    console.log(`listID=${listID}`);
    const { cursor: nextCursor, tweets: nextTweets } = await fetchTwitterByList(
      {
        token: apiKey,
        payload: {
          listID,
          count: 20,
          cursor,
        },
      }
    );
    setTweets((prev) => [...prev, ...nextTweets]);
    setCursor(nextCursor);
    setIsLoading(false);
  }, [searchQuery, cursor]);

  const tweetCount = useMemo(() => tweets.length, [tweets]);

  const renderTweets = useMemo(
    () =>
      tweets.map((tweet, index) => (
        <React.Fragment key={`${tweet.url}-${index}`}>
          <TweetResponder
            tweet={tweet}
            generateButtonID={generateButtonID}
            saveButtonID={saveButtonID}
          />
          {index < tweets.length - 1 && <Divider />}
        </React.Fragment>
      )),
    [tweets]
  );

  const generateAll = async () => {
    setIsGeneratingAll(true);
    const buttons = document.querySelectorAll(`#${generateButtonID}`);
    console.log("Buttons", buttons);
    let count = 0;
    // @ts-ignore
    for (const button of buttons) {
      count += 1;
      button.click();
      message.success(`Generated reply ${count}/${buttons.length}`);
      // sleep 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
    setIsGeneratingAll(false);
    notification.success({
      message: "Successfully Generated",
      description: `Generated ${count} replies`,
      duration: 30,
    });
  };

  const saveAll = async () => {
    const buttons = document.querySelectorAll(`#${saveButtonID}`);
    // @ts-ignore
    for (const button of buttons) {
      button.click();
    }
    notification.success({
      message: "Successfully Saved",
      description: `Saved all replies`,
    });
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {contextHolder}
      <div>
        <h1>List Strategy</h1>
        <p>List feed and reply to most recent tweets</p>
      </div>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div style={{ margin: "0px 10px" }}>
          <Input.Search
            placeholder="Enter List URL"
            allowClear
            size="large"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCursor(undefined);
            }}
            onSearch={handleSearch}
            style={{ maxWidth: "600px" }}
            loading={isLoading}
            prefix={
              <Popover
                content={
                  <span>
                    Learn how to use Twitter Lists.{" "}
                    <a
                      href="https://developer.twitter.com/en/docs/twitter-api/tweets/search/integrate/build-a-query"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      More info
                    </a>
                  </span>
                }
                title="Collecting Lists"
              >
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Popover>
            }
          />
          <Text type="secondary" style={{ display: "block", marginTop: "8px" }}>
            Showing {tweetCount} tweets
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              loading={isGeneratingAll}
              onClick={generateAll}
              style={{ borderRadius: "5px 0px 0px 5px" }}
              disabled={tweets.length === 0}
            >
              Generate All
            </Button>

            <Button
              onClick={saveAll}
              style={{ borderRadius: "0px 5px 5px 0px" }}
              disabled={tweets.length === 0}
            >
              Save All
            </Button>
          </div>
        </div>
        <Divider />
        {renderTweets}
        <Button
          disabled={tweets.length === 0}
          onClick={handleSearch}
          style={{ marginBottom: "80px" }}
          loading={isLoading}
        >
          Load More
        </Button>
      </Space>
      <QuickTweet />
    </div>
  );
};
