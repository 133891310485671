import { Tabs } from "antd";
import WriteStrategy from "../strategies/WriteStrategy";
import { SearchStrategy } from "../strategies/SearchStrategy";
import { ListStrategy } from "../strategies/ListStrategy";
import RaidStrategy from "../strategies/RaidStrategy";
import StickyActionBar from "../components/StickyActionBar";
const { TabPane } = Tabs;

const WritePage = () => {
  return (
    <div>
      <br />
      <br />
      <br />
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Search" key="1">
          <SearchStrategy />
        </TabPane>
        <TabPane tab="Blank" key="2">
          <WriteStrategy />
        </TabPane>
        <TabPane tab="List" key="3">
          <ListStrategy />
        </TabPane>
        <TabPane tab="Raid" key="4">
          <RaidStrategy />
        </TabPane>
      </Tabs>
      <StickyActionBar />
    </div>
  );
};

export default WritePage;
