import React from "react";
import { useAuth } from "../hooks/useAuth";
import {
  Form,
  Input,
  Card,
  Typography,
  Divider,
  Space,
  Button,
  message,
} from "antd";
import { CopyOutlined, EyeOutlined } from "@ant-design/icons";

const { Title } = Typography;

const SettingsPage: React.FC = () => {
  const { myProfile } = useAuth();

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Copied to clipboard");
      })
      .catch(() => {
        message.error("Failed to copy");
      });
  };

  const handleLogout = () => {
    localStorage.removeItem("apiKey");
    window.location.reload();
  };

  const renderFormItem = (
    label: string,
    value: string | number,
    disabled = false
  ) => (
    <Form.Item label={label}>
      <Space.Compact style={{ width: "100%" }}>
        <Input value={value} disabled={disabled} />
        <Button
          icon={<CopyOutlined />}
          onClick={() => copyToClipboard(String(value))}
        />
      </Space.Compact>
    </Form.Item>
  );

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  if (!myProfile) {
    return <span>Loading...</span>;
  }

  return (
    <div className="w-full max-w-[600px] mx-auto mt-8">
      <br />
      <br />
      <br />
      <Title level={2}>Settings</Title>
      <Form layout="vertical">
        <Space
          direction="vertical"
          className="w-full"
          style={{ width: "100%", maxWidth: "600px", padding: "0px 15px" }}
        >
          <Divider orientation="left">Account Information</Divider>
          {renderFormItem("Telegram", myProfile.telegram_username)}
          {renderFormItem("Email", myProfile.email)}
          {renderFormItem(
            "Wallet Balance",
            `$${myProfile.wallet_balance.toFixed(2)}`
          )}
          <Form.Item label="Plan">
            <Space.Compact style={{ width: "100%" }}>
              <Input value={myProfile.plan} disabled />
              <Button
                icon={<CopyOutlined />}
                onClick={() => copyToClipboard(String(myProfile.plan))}
              />
            </Space.Compact>
          </Form.Item>

          <Divider orientation="left">Integration Settings</Divider>
          <Form.Item label="Database URL">
            <Space.Compact style={{ width: "100%" }}>
              <Input value={myProfile.nocodb_host_url} disabled />
              <Button
                icon={<EyeOutlined />}
                onClick={() => openInNewTab(myProfile.nocodb_host_url)}
              />
            </Space.Compact>
          </Form.Item>
          {renderFormItem("Campaigns Table ID", myProfile.campaigns_table_id)}
          {renderFormItem(
            "Bookmark Tweets Table ID",
            myProfile.prospect_tweets_table_id
          )}
          {renderFormItem(
            "Bookmark Profiles Table ID",
            myProfile.prospect_profiles_table_id
          )}
          {renderFormItem("Quotes Table ID", myProfile.quotes_table_id)}
          {renderFormItem("Followers Table ID", myProfile.followers_table_id)}
          {renderFormItem(
            "Webscraping Table ID",
            myProfile.webscraping_table_id
          )}

          {renderFormItem(
            "Web Scraping Batch Size",
            myProfile.webscraping_batch_size
          )}

          <Divider orientation="left">Credentials</Divider>
          {renderFormItem("Customer UUID", myProfile.customer_uuid)}
          <Form.Item label="API Token">
            <Space.Compact style={{ width: "100%" }}>
              <Input.Password value={myProfile.token} />
              <Button
                icon={<CopyOutlined />}
                onClick={() => copyToClipboard(myProfile.token)}
              />
            </Space.Compact>
          </Form.Item>

          <Divider orientation="left"></Divider>
          <Button block danger onClick={handleLogout}>
            Log Out
          </Button>
        </Space>
      </Form>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default SettingsPage;
