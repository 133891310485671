import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTweetStore } from "./useTweetStore";
import { useShallow } from "zustand/react/shallow";
import { listAgents, useAgentStore } from "./useAgentStore";

export interface UserProfile {
  plan: string;
  email: string;
  token: string;
  customer_uuid: string;
  wallet_balance: number;
  nocodb_host_url: string;
  telegram_username: string;
  quotes_table_id: string;
  campaigns_table_id: string;
  followers_table_id: string;
  webscraping_table_id: string;
  webscraping_batch_size: number;
  prospect_tweets_table_id: string;
  prospect_profiles_table_id: string;
}

interface AuthContextType {
  apiKey: string | null;
  isAuth: boolean;
  myProfile: UserProfile | null;
  checkAPIKeyValid: (key?: string) => Promise<boolean>;
  authCheckComplete: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [myProfile, setMyProfile] = useState<UserProfile | null>(null);
  const [authCheckComplete, setAuthCheckComplete] = useState(false);
  const navigate = useNavigate();
  const { addAgents } = useAgentStore(
    useShallow((state) => ({
      addAgents: state.addAgents,
    }))
  );

  const {
    setPrompt,
    setCampaign,
    setQuoteSettings,
    setMentionSettings,
    setAgentSettings,
  } = useTweetStore(
    useShallow((state) => ({
      setPrompt: state.setPrompt,
      setCampaign: state.setCampaign,
      setQuoteSettings: state.setQuoteSettings,
      setMentionSettings: state.setMentionSettings,
      setAgentSettings: state.setAgentSettings,
    }))
  );

  const fetchUserProfile = async (
    token: string
  ): Promise<UserProfile | null> => {
    const body = {
      route: "GET/profile",
      token: token,
      payload: null,
    };
    try {
      const response = await fetch(
        "https://api.legions.bot/api/w/run-sybil/jobs/run_wait_result/f/f/runsybil/rest_api_runsybil",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer mXkec9NMmJV4gBE0xVUnzVlrUZLiVXfD",
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }

      const data: UserProfile = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching user profile:", error);
      return null;
    }
  };

  const checkAPIKeyValid = async (key?: string): Promise<boolean> => {
    const keyToCheck = key || apiKey || localStorage.getItem("apiKey");

    if (!keyToCheck) {
      setIsAuth(false);
      setMyProfile(null);
      return false;
    }

    const profile = await fetchUserProfile(keyToCheck);

    if (profile) {
      setApiKey(keyToCheck);
      localStorage.setItem("apiKey", keyToCheck);
      setIsAuth(true);
      setMyProfile(profile);
      return true;
    } else {
      setApiKey(null);
      localStorage.removeItem("apiKey");
      setIsAuth(false);
      setMyProfile(null);
      return false;
    }
  };

  const fetchAgents = async (token: string) => {
    const _agents = await listAgents({ token });
    console.log("_agents", _agents);
    const agents = _agents.map((agent: any) => ({
      agent_uuid: agent.agent_uuid,
      avatar_url: agent.avatar_url,
      name: agent.name,
      internal_notes: agent.internal_notes,
      twitter_url: agent.twitter_url,
      twitter_username: agent.twitter_username,
      teams: (agent.teams || "").split(",").map((team: string) => team.trim()),
      status: agent.status,
      tweet_timeline: agent.tweet_timeline,
    }));
    addAgents(agents);
  };

  useEffect(() => {
    const validateApiKey = async () => {
      const storedKey = localStorage.getItem("apiKey");
      if (storedKey) {
        await checkAPIKeyValid(storedKey);
      } else {
        setIsAuth(false);
        setMyProfile(null);
      }
      setAuthCheckComplete(true);
      if (storedKey) {
        await fetchAgents(storedKey);
      }
    };
    validateApiKey();

    const _cachedPrompt = localStorage.getItem("prompt");
    if (_cachedPrompt) {
      setPrompt(_cachedPrompt);
    }
    const _cachedCampaign = localStorage.getItem("campaign");
    if (_cachedCampaign) {
      setCampaign(_cachedCampaign);
    }
    const __quoteSettigns = localStorage.getItem("quoteSettings");
    if (__quoteSettigns) {
      setQuoteSettings(JSON.parse(__quoteSettigns));
    }
    const __mentionSettings = localStorage.getItem("mentionSettings");
    if (__mentionSettings) {
      setMentionSettings(JSON.parse(__mentionSettings));
    }
    const __agentSettings = localStorage.getItem("agentSettings");
    if (__agentSettings) {
      setAgentSettings(JSON.parse(__agentSettings));
    }
  }, []);

  useEffect(() => {
    if (authCheckComplete && !isAuth && window.location.pathname !== "/login") {
      navigate("/login");
    }
  }, [authCheckComplete, isAuth, navigate]);

  return (
    <AuthContext.Provider
      value={{ apiKey, isAuth, myProfile, checkAPIKeyValid, authCheckComplete }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
