import React, { useEffect, useState } from "react";
import {
  Table,
  Avatar,
  Tag,
  Dropdown,
  Input,
  Select,
  Popover,
  Button,
  Space,
  Modal,
  Form,
  message,
} from "antd";
import {
  DownOutlined,
  EditOutlined,
  FileTextOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import {
  Agent,
  calculateSpeedScore,
  createAgent,
  useAgentStore,
} from "../hooks/useAgentStore";
import { useAuth } from "../hooks/useAuth";
import { useShallow } from "zustand/react/shallow";
import QuickTweet from "../components/QuickTweet";

const { Search } = Input;
const { Option } = Select;

const DEFAULT_AVATAR_URL =
  "https://as2.ftcdn.net/v2/jpg/02/99/96/99/1000_F_299969916_4jzmaeFySRYhTs5cu0QDxCqD08wrBVj5.jpg";

const AgentsPage: React.FC = () => {
  const { agents } = useAgentStore(
    useShallow((state) => ({
      agents: state.agents,
    }))
  );
  const [filteredAgents, setFilteredAgents] = useState<Agent[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddingAgent, setIsAddingAgent] = useState(false);
  const { apiKey } = useAuth();

  const [form] = Form.useForm();

  useEffect(() => {
    setFilteredAgents(agents);
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    const filtered = agents.filter((agent) => {
      const matchesSearch =
        agent.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agent.twitter_username.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesTeams =
        selectedTeams.length === 0 ||
        selectedTeams.some((team) => agent.teams.includes(team));
      return matchesSearch && matchesTeams;
    });
    setFilteredAgents(filtered);
  }, [agents, searchTerm, selectedTeams]);

  const allTeams = Array.from(new Set(agents.flatMap((agent) => agent.teams)));

  const columns: ColumnsType<Agent> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Space>
          <Avatar src={record.avatar_url || DEFAULT_AVATAR_URL} />
          <a
            href={`https://x.com/${record.twitter_username}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record.name} (@{record.twitter_username})
          </a>
        </Space>
      ),
    },
    {
      title: "Teams",
      dataIndex: "teams",
      key: "teams",
      render: (teams: string[]) => (
        <>
          {teams.map((team) => (
            <Tag key={team} color="default">
              {team}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        let color = "default";
        switch (status) {
          case "requested":
            color = "blue";
            break;
          case "warmup":
            color = "orange";
            break;
          case "healthy":
            color = "green";
            break;
          case "warning":
            color = "yellow";
            break;
          case "error":
            color = "red";
            break;
          case "asleep":
            color = "gray";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Activity",
      key: "activity",
      render: (_, record) => {
        const { score, memo } = calculateSpeedScore(
          record.tweet_timeline || ""
        );
        let color = "green";
        if (score > 1) {
          color = "red";
        } else if (score >= 0.7) {
          color = "orange";
        }
        return (
          <Popover content={memo} trigger="hover">
            <Tag color={color}>{memo}</Tag>
          </Popover>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "edit",
                label: (
                  <Popover content="Edit in database" trigger="hover">
                    <a
                      href="https://google.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Edit
                    </a>
                  </Popover>
                ),
                icon: <EditOutlined />,
              },
              {
                key: "notes",
                label: (
                  <Popover content={record.internal_notes} trigger="hover">
                    Internal Notes
                  </Popover>
                ),
                icon: <FileTextOutlined />,
              },
            ],
          }}
        >
          <Button>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleTeamChange = (values: string[]) => {
    setSelectedTeams(values);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleAddAgent = async (values: { agent_uuid: string }) => {
    if (!apiKey) return;
    setIsAddingAgent(true);
    try {
      const res = await createAgent({
        token: apiKey,
        payload: {
          agent_uuid: values.agent_uuid,
        },
      });
      setIsAddingAgent(false);
      setIsModalVisible(false);
      form.resetFields();
      console.log("Agent added:", res);
      if (res) {
        // refresh window
        message.success(
          "Agent added successfully, refreshing window please wait..."
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        message.error("Error adding agent");
      }
    } catch (error) {
      console.error("Error adding agent:", error);
      setIsAddingAgent(false);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  console.log("Agents", filteredAgents);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: 16,
          padding: "16px",
        }}
      >
        <Button
          icon={<PlusOutlined />}
          onClick={showModal}
          style={{ marginRight: 110, marginTop: 5 }}
        >
          Add Agent
        </Button>

        <QuickTweet />
      </div>

      <h1>Agents</h1>
      <p>
        Currently we provide all Twitter accounts.
        <br />
        Soon you will be able to bring your own for self-branding.
      </p>
      <br />
      <div style={{ marginBottom: 16 }}>
        <Search
          placeholder="Search agents"
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 200, marginRight: 16 }}
        />
        <Select
          mode="multiple"
          style={{ width: 200 }}
          placeholder="Select teams"
          onChange={handleTeamChange}
        >
          {allTeams.map((team) => (
            <Option key={team} value={team}>
              {team}
            </Option>
          ))}
        </Select>
      </div>
      <div style={{ padding: "0px 20px" }}>
        <Table
          columns={columns}
          dataSource={filteredAgents}
          rowKey="twitter_username"
        />
      </div>

      <Modal
        title="Add New Agent"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleAddAgent} layout="vertical">
          <Form.Item
            name="agent_uuid"
            label="Agent UUID"
            rules={[{ required: true, message: "Please enter the agent_uuid" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <span>
              Need help? Check out our{" "}
              <a
                href="https://example.com/help"
                target="_blank"
                rel="noopener noreferrer"
              >
                guide on adding agents
              </a>
              .
            </span>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" loading={isAddingAgent}>
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AgentsPage;
