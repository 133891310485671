import React, { useState } from "react";
import { Layout, Button, Drawer, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import {
  useLocation,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import WritePage from "./pages/WritePage";
import CampaignsPage from "./pages/CampaignsPage";
import BookmarksPage from "./pages/BookmarksPage";
import QuotesPage from "./pages/QuotesPage";
import DownloadsPage from "./pages/DownloadsPage";
import SettingsPage from "./pages/SettingsPage";
import LoginPage from "./pages/LoginPage";
import { useAuth } from "./hooks/useAuth";
import AgentsPage from "./pages/AgentsPage";

const Router: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { myProfile } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    { key: "/write", label: "Write" },
    { key: "/campaigns", label: "Campaigns" },
    { key: "/agents", label: "Agents" },
    { key: "/quotes", label: "Quotes" },
    { key: "/bookmarks", label: "Bookmarks" },
    { key: "/downloads", label: "Downloads" },
    { key: "/settings", label: "Settings" },
  ];

  const handleMenuClick = (key: string) => {
    setDrawerVisible(false);
    navigate(key);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Button
        icon={<MenuOutlined />}
        onClick={() => setDrawerVisible(true)}
        style={{
          position: "fixed",
          top: 16,
          left: 16,
          zIndex: 1000,
        }}
      >
        <span style={{ fontSize: "1.3rem" }}>🍀</span>
      </Button>
      <Drawer
        title={
          <span>
            <span style={{ fontSize: "1.5rem", marginRight: "5px" }}>🍀</span>
            {` TweetMob `}
            <span
              style={{ fontSize: "0.9rem", marginLeft: "5px", fontWeight: 400 }}
            >
              {myProfile?.telegram_username}
            </span>
          </span>
        }
        placement="left"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={320}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[location.pathname]}
            items={menuItems.map((item) => ({
              key: item.key,
              label: item.label,
              onClick: () => handleMenuClick(item.key),
            }))}
          />
          <a href="https://t.me/tweetmob_bot" target="_blank">
            <Button
              block
              type="primary"
              size="large"
              style={{
                fontWeight: 500,
                fontSize: "1.3rem",
                padding: "30px 10px",
              }}
            >
              Chat Telegram
            </Button>
          </a>
        </div>
      </Drawer>
      <Routes>
        <Route path="/" element={<WritePage />} />
        <Route path="/write" element={<WritePage />} />
        <Route path="/campaigns" element={<CampaignsPage />} />
        <Route path="/bookmarks" element={<BookmarksPage />} />
        <Route path="/agents" element={<AgentsPage />} />
        <Route path="/quotes" element={<QuotesPage />} />
        <Route path="/downloads" element={<DownloadsPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </Layout>
  );
};

export default Router;
