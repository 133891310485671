import { TweetPreview } from "../components/TweetCard";

export interface ListTimelineType {
  data: Data;
}

export const parseListTimeline = (
  listTimeline: ListTimelineType
): { cursor: string; tweets: TweetPreview[] } => {
  const tweets: TweetPreview[] = [];
  let cursor = "";

  const instructions =
    listTimeline.data.list.tweets_timeline.timeline.instructions;
  const entries =
    instructions.find(
      (instruction) => instruction.type === "TimelineAddEntries"
    )?.entries || [];

  for (const entry of entries) {
    if (
      entry.content.entryType === "TimelineTimelineCursor" &&
      entry.content.cursorType === "Bottom"
    ) {
      cursor = entry.content.value || "";
      continue;
    }

    if (
      entry.content.entryType === "TimelineTimelineItem" &&
      entry.content.itemContent?.tweet_results?.result
    ) {
      const tweetResult = entry.content.itemContent.tweet_results.result;
      const legacy = tweetResult.legacy;

      if (!legacy) continue;

      const tweet: TweetPreview = {
        url: `https://twitter.com/${legacy.user_id_str}/status/${legacy.id_str}`,
        text: legacy.full_text,
        views: parseInt(tweetResult.views?.count || "0"),
        likes: legacy.favorite_count,
        retweets: legacy.retweet_count,
        replies: legacy.reply_count,
        quoteCount: legacy.quote_count,
        author: {
          name: tweetResult.core?.user_results.result.legacy.name || "",
          screenName:
            tweetResult.core?.user_results.result.legacy.screen_name || "",
          profileImageUrl:
            tweetResult.core?.user_results.result.legacy
              .profile_image_url_https || "",
          isVerified:
            tweetResult.core?.user_results.result.is_blue_verified || false,
          url: `https://twitter.com/${tweetResult.core?.user_results.result.legacy.screen_name}`,
        },
        createdAt: legacy.created_at,
        source: legacy.source || "",
      };

      if (legacy.extended_entities?.media) {
        tweet.media = legacy.extended_entities.media.map((media) => ({
          mediaUrl: media.media_url_https,
          type: media.type,
        }));
      }

      if (legacy.is_quote_status && tweetResult.quoted_status_result?.result) {
        const quotedTweet = tweetResult.quoted_status_result.result;
        const quotedLegacy = quotedTweet.legacy;

        if (quotedLegacy) {
          tweet.quotedTweet = {
            url: `https://twitter.com/${quotedLegacy.user_id_str}/status/${quotedLegacy.id_str}`,
            text: quotedLegacy.full_text,
            views: parseInt(quotedTweet.views?.count || "0"),
            likes: quotedLegacy.favorite_count,
            retweets: quotedLegacy.retweet_count,
            replies: quotedLegacy.reply_count,
            quoteCount: quotedLegacy.quote_count,
            author: {
              name: quotedTweet.core?.user_results.result.legacy.name || "",
              screenName:
                quotedTweet.core?.user_results.result.legacy.screen_name || "",
              profileImageUrl:
                quotedTweet.core?.user_results.result.legacy
                  .profile_image_url_https || "",
              isVerified:
                quotedTweet.core?.user_results.result.is_blue_verified || false,
              url: `https://twitter.com/${quotedTweet.core?.user_results.result.legacy.screen_name}`,
            },
            createdAt: quotedLegacy.created_at,
            source: quotedLegacy.source || "",
          };

          if (quotedLegacy.extended_entities?.media) {
            tweet.quotedTweet.media = quotedLegacy.extended_entities.media.map(
              (media) => ({
                mediaUrl: media.media_url_https,
                type: media.type,
              })
            );
          }
        }
      }

      tweets.push(tweet);
    }
  }

  return { cursor, tweets };
};

interface Data {
  list: List;
}
interface List {
  tweets_timeline: Tweetstimeline;
}
interface Tweetstimeline {
  timeline: Timeline;
}
interface Timeline {
  instructions: Instruction[];
  metadata: Metadata2;
}
interface Metadata2 {
  scribeConfig: ScribeConfig;
}
interface ScribeConfig {
  page: string;
}
interface Instruction {
  type: string;
  entries: Entry[];
}
interface Entry {
  entryId: string;
  sortIndex: string;
  content: Content;
}
interface Content {
  entryType: string;
  __typename: string;
  itemContent?: ItemContent;
  clientEventInfo?: ClientEventInfo;
  items?: Item2[];
  metadata?: Metadata;
  displayType?: string;
  value?: string;
  cursorType?: string;
}
interface Metadata {
  conversationMetadata: ConversationMetadata;
}
interface ConversationMetadata {
  allTweetIds: string[];
  enableDeduplication: boolean;
}
interface Item2 {
  entryId: string;
  item: Item;
}
interface Item {
  itemContent: ItemContent2;
  clientEventInfo: ClientEventInfo2;
}
interface ClientEventInfo2 {
  component: string;
  element: string;
  details: Details;
}
interface ItemContent2 {
  itemType: string;
  __typename: string;
  tweet_results: Tweetresults2;
  tweetDisplayType: string;
}
interface Tweetresults2 {
  result: Result25;
}
interface Result25 {
  __typename: string;
  rest_id: string;
  core: Core7;
  unmention_data: Affiliateshighlightedlabel;
  edit_control: Editcontrol;
  is_translatable: boolean;
  views: Views2;
  source: string;
  legacy: Legacy22;
}
interface Legacy22 {
  bookmark_count: number;
  bookmarked: boolean;
  created_at: string;
  conversation_id_str: string;
  display_text_range: number[];
  entities: Entities14;
  extended_entities?: Extendedentities7;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  is_quote_status: boolean;
  lang: string;
  possibly_sensitive?: boolean;
  possibly_sensitive_editable?: boolean;
  quote_count: number;
  reply_count: number;
  retweet_count: number;
  retweeted: boolean;
  user_id_str: string;
  id_str: string;
  in_reply_to_screen_name?: string;
  in_reply_to_status_id_str?: string;
  in_reply_to_user_id_str?: string;
}
interface Extendedentities7 {
  media: Media8[];
}
interface Entities14 {
  hashtags: any[];
  media?: Media8[];
  symbols: any[];
  timestamps: any[];
  urls: Url[][];
  user_mentions: Usermention[][];
}
interface Media8 {
  display_url: string;
  expanded_url: string;
  id_str: string;
  indices: number[];
  media_key: string;
  media_url_https: string;
  type: string;
  url: string;
  ext_media_availability: Extmediaavailability;
  features: Features3;
  sizes: Sizes;
  original_info: Originalinfo2;
}
interface Features3 {
  large: Large4;
  medium: Large4;
  small: Large4;
  orig: Large4;
}
interface Large4 {
  faces: Face[];
}
interface Core7 {
  user_results: Userresults9;
}
interface Userresults9 {
  result: Result24;
}
interface Result24 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy15;
  professional: Professional3;
}
interface ClientEventInfo {
  component: string;
  element?: string;
  details: Details;
}
interface Details {
  timelinesDetails: TimelinesDetails;
}
interface TimelinesDetails {
  injectionType: string;
}
interface ItemContent {
  itemType: string;
  __typename: string;
  tweet_results: Tweetresults;
  tweetDisplayType: string;
}
interface Tweetresults {
  result: Result23;
}
interface Result23 {
  __typename: string;
  rest_id?: string;
  core?: Core;
  unmention_data?: Affiliateshighlightedlabel;
  edit_control?: Editcontrol;
  is_translatable?: boolean;
  views?: Views;
  source?: string;
  quoted_status_result?: Quotedstatusresult;
  legacy?: Legacy14;
  card?: Card4;
  tweet?: Tweet2;
  limitedActionResults?: LimitedActionResults;
}
interface Tweet2 {
  rest_id: string;
  core: Core5;
  unmention_data: Affiliateshighlightedlabel;
  edit_control: Editcontrol;
  is_translatable: boolean;
  views: Views3;
  source: string;
  legacy: Legacy21;
}
interface Legacy21 {
  bookmark_count: number;
  bookmarked: boolean;
  created_at: string;
  conversation_id_str: string;
  display_text_range: number[];
  entities: Entities11;
  extended_entities?: Extendedentities5;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  is_quote_status: boolean;
  lang: string;
  possibly_sensitive?: boolean;
  possibly_sensitive_editable?: boolean;
  quote_count: number;
  reply_count: number;
  retweet_count: number;
  retweeted: boolean;
  user_id_str: string;
  id_str: string;
  retweeted_status_result: Retweetedstatusresult2;
}
interface Retweetedstatusresult2 {
  result: Result22;
}
interface Result22 {
  __typename: string;
  tweet: Tweet;
  limitedActionResults?: LimitedActionResults;
}
interface LimitedActionResults {
  limited_actions: Limitedaction[];
}
interface Limitedaction {
  action: string;
}
interface Tweet {
  rest_id: string;
  core: Core6;
  unmention_data: Affiliateshighlightedlabel;
  edit_control: Editcontrol;
  is_translatable: boolean;
  views: Views2;
  source: string;
  legacy: Legacy20;
}
interface Legacy20 {
  bookmark_count: number;
  bookmarked: boolean;
  created_at: string;
  conversation_id_str: string;
  display_text_range: number[];
  entities: Entities13;
  extended_entities: Extendedentities6;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  is_quote_status: boolean;
  lang: string;
  possibly_sensitive: boolean;
  possibly_sensitive_editable: boolean;
  quote_count: number;
  reply_count: number;
  retweet_count: number;
  retweeted: boolean;
  user_id_str: string;
  id_str: string;
}
interface Extendedentities6 {
  media: Media7[];
}
interface Entities13 {
  hashtags: Hashtag[];
  media: Media7[];
  symbols: Hashtag[];
  timestamps: any[];
  urls: Url[];
  user_mentions: any[];
}
interface Media7 {
  display_url: string;
  expanded_url: string;
  id_str: string;
  indices: number[];
  media_key: string;
  media_url_https: string;
  type: string;
  url: string;
  ext_media_availability: Extmediaavailability;
  features: Features2;
  sizes: Sizes;
  original_info: Originalinfo2;
}
interface Core6 {
  user_results: Userresults8;
}
interface Userresults8 {
  result: Result21;
}
interface Result21 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy19;
  professional?: Professional2;
}
interface Legacy19 {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities12;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: string[];
  possibly_sensitive: boolean;
  profile_banner_url: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  verified: boolean;
  want_retweets: boolean;
  withheld_in_countries: any[];
  url?: string;
}
interface Entities12 {
  description: Url2;
  url?: Url2;
}
interface Extendedentities5 {
  media: Media6[];
}
interface Entities11 {
  hashtags: any[];
  media?: Media6[];
  symbols: Hashtag[];
  timestamps: any[];
  urls: any[];
  user_mentions: Usermention[];
}
interface Media6 {
  display_url: string;
  expanded_url: string;
  id_str: string;
  indices: number[];
  media_key: string;
  media_url_https: string;
  source_status_id_str: string;
  source_user_id_str: string;
  type: string;
  url: string;
  ext_media_availability: Extmediaavailability;
  features: Features2;
  sizes: Sizes;
  original_info: Originalinfo2;
}
interface Originalinfo2 {
  height: number;
  width: number;
  focus_rects: Face[];
}
interface Views3 {
  state: string;
}
interface Core5 {
  user_results: Userresults7;
}
interface Userresults7 {
  result: Result20;
}
interface Result20 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy18;
  professional: Professional;
}
interface Legacy18 {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities10;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: string[];
  possibly_sensitive: boolean;
  profile_banner_url: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  url?: string;
  verified: boolean;
  want_retweets: boolean;
  withheld_in_countries: any[];
}
interface Entities10 {
  description: Description2;
  url?: Url2;
}
interface Card4 {
  rest_id: string;
  legacy: Legacy17;
}
interface Legacy17 {
  binding_values: Bindingvalue3[];
  card_platform: Cardplatform;
  name: string;
  url: string;
  user_refs_results: (Userrefsresult3 | Userrefsresults2 | Userrefsresults3)[];
}
interface Userrefsresults3 {
  result: Result19;
}
interface Result19 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy16;
}
interface Userrefsresults2 {
  result: Result18;
}
interface Result18 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy16;
  professional: Professional3;
}
interface Legacy16 {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities9;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: any[];
  possibly_sensitive: boolean;
  profile_banner_url: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  url: string;
  verified: boolean;
  verified_type: string;
  want_retweets: boolean;
  withheld_in_countries: any[];
}
interface Userrefsresult3 {
  result: Result17;
}
interface Result17 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy15;
  professional: Professional2;
}
interface Legacy15 {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities9;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: string[];
  possibly_sensitive: boolean;
  profile_banner_url: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  url: string;
  verified: boolean;
  want_retweets: boolean;
  withheld_in_countries: any[];
}
interface Entities9 {
  description: Description2;
  url: Url2;
}
interface Bindingvalue3 {
  key: string;
  value: Value3;
}
interface Value3 {
  image_value?: Imagevalue2;
  type: string;
  string_value?: string;
  scribe_key?: string;
  user_value?: Uservalue;
  image_color_value?: Imagecolorvalue;
}
interface Imagevalue2 {
  height: number;
  width: number;
  url: string;
  alt?: string;
}
interface Legacy14 {
  bookmark_count: number;
  bookmarked: boolean;
  created_at: string;
  conversation_id_str: string;
  display_text_range: number[];
  entities: Entities4;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  is_quote_status: boolean;
  lang: string;
  quote_count: number;
  quoted_status_id_str?: string;
  quoted_status_permalink?: Quotedstatuspermalink;
  reply_count: number;
  retweet_count: number;
  retweeted: boolean;
  user_id_str: string;
  id_str: string;
  extended_entities?: Extendedentities2;
  possibly_sensitive?: boolean;
  possibly_sensitive_editable?: boolean;
  retweeted_status_result?: Retweetedstatusresult;
  conversation_control?: Conversationcontrol;
  source?: string;
}
interface Retweetedstatusresult {
  result: Result16;
}
interface Result16 {
  __typename: string;
  rest_id: string;
  core: Core3;
  unmention_data: Affiliateshighlightedlabel;
  edit_control: Editcontrol;
  is_translatable: boolean;
  views: Views;
  source: string;
  legacy: Legacy7;
  note_tweet?: Notetweet2;
  quoted_status_result?: Quotedstatusresult2;
  card?: Card3;
}
interface Card3 {
  rest_id: string;
  legacy: Legacy13;
}
interface Legacy13 {
  binding_values: Bindingvalue2[];
  card_platform: Cardplatform;
  name: string;
  url: string;
  user_refs_results: any[];
}
interface Bindingvalue2 {
  key: string;
  value: Value2;
}
interface Value2 {
  image_value?: Imagevalue;
  type: string;
  string_value?: string;
  scribe_key?: string;
  image_color_value?: Imagecolorvalue;
}
interface Quotedstatusresult2 {
  result: Result15;
}
interface Result15 {
  __typename: string;
  rest_id: string;
  core: Core4;
  card?: Card2;
  unmention_data: Affiliateshighlightedlabel;
  edit_control: Editcontrol;
  is_translatable: boolean;
  views: Views;
  source: string;
  legacy: Legacy12;
  note_tweet?: Notetweet3;
  quotedRefResult?: QuotedRefResult;
}
interface Notetweet3 {
  is_expandable: boolean;
  note_tweet_results: Notetweetresults3;
}
interface Notetweetresults3 {
  result: Result14;
}
interface Result14 {
  id: string;
  text: string;
  entity_set: Entityset2;
  richtext: Richtext2;
}
interface Richtext2 {
  richtext_tags: Richtexttag[];
}
interface Entityset2 {
  hashtags: any[];
  symbols: any[];
  urls: any[];
  user_mentions: Usermention[];
}
interface Legacy12 {
  bookmark_count: number;
  bookmarked: boolean;
  created_at: string;
  conversation_id_str: string;
  display_text_range: number[];
  entities: Entities8;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  is_quote_status: boolean;
  lang: string;
  possibly_sensitive?: boolean;
  possibly_sensitive_editable?: boolean;
  quote_count: number;
  reply_count: number;
  retweet_count: number;
  retweeted: boolean;
  user_id_str: string;
  id_str: string;
  quoted_status_id_str?: string;
  quoted_status_permalink?: Quotedstatuspermalink;
  extended_entities?: Extendedentities4;
  in_reply_to_screen_name?: string;
  in_reply_to_status_id_str?: string;
  in_reply_to_user_id_str?: string;
}
interface Extendedentities4 {
  media: Media5[];
}
interface Entities8 {
  hashtags: Hashtag[];
  symbols: any[];
  timestamps: any[];
  urls: Url[];
  user_mentions: Usermention[];
  media?: Media5[];
}
interface Media5 {
  display_url: string;
  expanded_url: string;
  id_str: string;
  indices: number[];
  media_key: string;
  media_url_https: string;
  source_status_id_str?: string;
  source_user_id_str?: string;
  type: string;
  url: string;
  additional_media_info?: Additionalmediainfo4;
  ext_media_availability: Extmediaavailability2;
  sizes: Sizes;
  original_info: Originalinfo;
  video_info?: Videoinfo;
  features?: Features2;
}
interface Features2 {
  large: Large3;
  medium: Large3;
  small: Large3;
  orig: Large3;
}
interface Large3 {
  faces: any[];
}
interface Extmediaavailability2 {
  reason?: string;
  status: string;
}
interface Additionalmediainfo4 {
  monetizable: boolean;
  source_user: Sourceuser2;
}
interface Sourceuser2 {
  user_results: Userresults6;
}
interface Userresults6 {
  result: Result13;
}
interface Result13 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy11;
}
interface Legacy11 {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities7;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: any[];
  possibly_sensitive: boolean;
  profile_banner_url: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  verified: boolean;
  want_retweets: boolean;
  withheld_in_countries: any[];
}
interface Entities7 {
  description: Description2;
}
interface Description2 {
  urls: any[];
}
interface Card2 {
  rest_id: string;
  legacy: Legacy10;
}
interface Legacy10 {
  binding_values: Bindingvalue[];
  card_platform: Cardplatform;
  name: string;
  url: string;
  user_refs_results: Userrefsresult2[];
}
interface Userrefsresult2 {
  result: Result12;
}
interface Result12 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy9;
  professional: Professional2;
}
interface Legacy9 {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities6;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: string[];
  possibly_sensitive: boolean;
  profile_banner_url: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  url: string;
  verified: boolean;
  verified_type: string;
  want_retweets: boolean;
  withheld_in_countries: any[];
}
interface Core4 {
  user_results: Userresults5;
}
interface Userresults5 {
  result: Result11;
}
interface Result11 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel2;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy8;
  professional?: Professional2;
  super_follow_eligible?: boolean;
}
interface Legacy8 {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities6;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: string[];
  possibly_sensitive: boolean;
  profile_banner_url: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  url: string;
  verified: boolean;
  verified_type?: string;
  want_retweets: boolean;
  withheld_in_countries: any[];
}
interface Entities6 {
  description: Description;
  url: Url2;
}
interface Notetweet2 {
  is_expandable: boolean;
  note_tweet_results: Notetweetresults2;
}
interface Notetweetresults2 {
  result: Result10;
}
interface Result10 {
  id: string;
  text: string;
  entity_set: Entityset;
  richtext: Richtext;
  media?: Media2;
}
interface Legacy7 {
  bookmark_count: number;
  bookmarked: boolean;
  created_at: string;
  conversation_id_str: string;
  display_text_range: number[];
  entities: Entities5;
  extended_entities?: Extendedentities3;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  in_reply_to_screen_name?: string;
  in_reply_to_status_id_str?: string;
  in_reply_to_user_id_str?: string;
  is_quote_status: boolean;
  lang: string;
  possibly_sensitive?: boolean;
  possibly_sensitive_editable?: boolean;
  quote_count: number;
  reply_count: number;
  retweet_count: number;
  retweeted: boolean;
  user_id_str: string;
  id_str: string;
  quoted_status_id_str?: string;
  quoted_status_permalink?: Quotedstatuspermalink;
  conversation_control?: Conversationcontrol;
}
interface Conversationcontrol {
  policy: string;
  conversation_owner_results: Conversationownerresults;
}
interface Conversationownerresults {
  result: Result9;
}
interface Result9 {
  __typename: string;
  legacy: Legacy6;
}
interface Legacy6 {
  screen_name: string;
}
interface Extendedentities3 {
  media: Media4[];
}
interface Entities5 {
  hashtags: Hashtag[];
  media?: Media4[];
  symbols: Hashtag[];
  timestamps: any[];
  urls: Url[];
  user_mentions: Usermention[];
}
interface Media4 {
  display_url: string;
  expanded_url: string;
  id_str: string;
  indices: number[];
  media_key: string;
  media_url_https: string;
  type: string;
  url: string;
  ext_media_availability: Extmediaavailability;
  features?: Features;
  sizes: Sizes;
  original_info: Originalinfo;
  additional_media_info?: Additionalmediainfo3;
  video_info?: Videoinfo2;
  source_status_id_str?: string;
  source_user_id_str?: string;
  ext_alt_text?: string;
}
interface Additionalmediainfo3 {
  monetizable: boolean;
  title?: string;
  description?: string;
  embeddable?: boolean;
  source_user?: Sourceuser;
}
interface Sourceuser {
  user_results: Userresults4;
}
interface Userresults4 {
  result: Result8;
}
interface Result8 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy4;
  professional: Professional3;
}
interface Professional3 {
  rest_id: string;
  professional_type: string;
  category: any[];
}
interface Core3 {
  user_results: Userresults3;
}
interface Userresults3 {
  result: Result7;
}
interface Result7 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel2;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy2;
  professional?: Professional;
  super_follow_eligible?: boolean;
}
interface Affiliateshighlightedlabel2 {
  label?: Label;
}
interface Label {
  url: Url3;
  badge: Badge;
  description: string;
  userLabelType: string;
  userLabelDisplayType: string;
}
interface Badge {
  url: string;
}
interface Url3 {
  url: string;
  urlType: string;
}
interface Extendedentities2 {
  media: Media3[];
}
interface Entities4 {
  hashtags: Hashtag[];
  symbols: Hashtag[];
  timestamps: any[];
  urls: (Url | Urls2)[];
  user_mentions: Usermention[];
  media?: Media3[];
}
interface Media3 {
  display_url: string;
  expanded_url: string;
  id_str: string;
  indices: number[];
  media_key: string;
  media_url_https: string;
  source_status_id_str?: string;
  source_user_id_str?: string;
  type: string;
  url: string;
  ext_media_availability: Extmediaavailability;
  features?: Features;
  sizes: Sizes;
  original_info: Originalinfo;
  additional_media_info?: Additionalmediainfo2;
  video_info?: Videoinfo2;
  ext_alt_text?: string;
}
interface Videoinfo2 {
  aspect_ratio: number[];
  duration_millis?: number;
  variants: Variant[];
}
interface Additionalmediainfo2 {
  monetizable: boolean;
  source_user?: Core2;
  title?: string;
  description?: string;
  embeddable?: boolean;
}
interface Urls2 {
  display_url: string;
  url: string;
  indices: number[];
}
interface Quotedstatusresult {
  result: Result6;
}
interface Result6 {
  __typename: string;
  rest_id: string;
  core: Core2;
  unmention_data: Affiliateshighlightedlabel;
  edit_control: Editcontrol2;
  is_translatable: boolean;
  views: Views2;
  source: string;
  legacy: Legacy3;
  note_tweet?: Notetweet;
  card?: Card;
  previous_counts?: Previouscounts;
  quotedRefResult?: QuotedRefResult;
}
interface QuotedRefResult {
  result: Result5;
}
interface Result5 {
  __typename: string;
  rest_id: string;
}
interface Previouscounts {
  bookmark_count: number;
  favorite_count: number;
  quote_count: number;
  reply_count: number;
  retweet_count: number;
}
interface Card {
  rest_id: string;
  legacy: Legacy5;
}
interface Legacy5 {
  binding_values: Bindingvalue[];
  card_platform: Cardplatform;
  name: string;
  url: string;
  user_refs_results: Userrefsresult[];
}
interface Userrefsresult {
  result: Result4;
}
interface Result4 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy4;
  professional: Professional2;
}
interface Professional2 {
  rest_id: string;
  professional_type: string;
  category: Category[];
}
interface Legacy4 {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities3;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: string[];
  possibly_sensitive: boolean;
  profile_banner_url: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  url: string;
  verified: boolean;
  verified_type: string;
  want_retweets: boolean;
  withheld_in_countries: any[];
}
interface Entities3 {
  description: Url2;
  url: Url2;
}
interface Cardplatform {
  platform: Platform;
}
interface Platform {
  audience: Audience;
  device: Device;
}
interface Device {
  name: string;
  version: string;
}
interface Audience {
  name: string;
}
interface Bindingvalue {
  key: string;
  value: Value;
}
interface Value {
  image_value?: Imagevalue;
  type: string;
  string_value?: string;
  scribe_key?: string;
  user_value?: Uservalue;
  image_color_value?: Imagecolorvalue;
}
interface Imagecolorvalue {
  palette: Palette[];
}
interface Palette {
  rgb: Rgb;
  percentage: number;
}
interface Rgb {
  blue: number;
  green: number;
  red: number;
}
interface Uservalue {
  id_str: string;
  path: any[];
}
interface Imagevalue {
  height: number;
  width: number;
  url: string;
}
interface Notetweet {
  is_expandable: boolean;
  note_tweet_results: Notetweetresults;
}
interface Notetweetresults {
  result: Result3;
}
interface Result3 {
  id: string;
  text: string;
  entity_set: Entityset;
  richtext?: Richtext;
  media?: Media2;
}
interface Media2 {
  inline_media: any[];
}
interface Richtext {
  richtext_tags: Richtexttag[];
}
interface Richtexttag {
  from_index: number;
  to_index: number;
  richtext_types: string[];
}
interface Entityset {
  hashtags: Hashtag[];
  symbols: Hashtag[];
  timestamps?: any[];
  urls: Url[];
  user_mentions: Usermention[];
}
interface Legacy3 {
  bookmark_count: number;
  bookmarked: boolean;
  created_at: string;
  conversation_id_str: string;
  display_text_range: number[];
  entities: Entities2;
  extended_entities?: Extendedentities;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  is_quote_status: boolean;
  lang: string;
  possibly_sensitive?: boolean;
  possibly_sensitive_editable?: boolean;
  quote_count: number;
  reply_count: number;
  retweet_count: number;
  retweeted: boolean;
  user_id_str: string;
  id_str: string;
  in_reply_to_screen_name?: string;
  in_reply_to_status_id_str?: string;
  in_reply_to_user_id_str?: string;
  quoted_status_id_str?: string;
  quoted_status_permalink?: Quotedstatuspermalink;
  source?: string;
}
interface Quotedstatuspermalink {
  url: string;
  expanded: string;
  display: string;
}
interface Extendedentities {
  media: Media[];
}
interface Entities2 {
  hashtags: Hashtag[];
  media?: Media[];
  symbols: Hashtag[];
  timestamps: any[];
  urls: Url[];
  user_mentions: Usermention[];
}
interface Usermention {
  id_str: string;
  name: string;
  screen_name: string;
  indices: number[];
}
interface Media {
  display_url: string;
  expanded_url: string;
  id_str: string;
  indices: number[];
  media_key: string;
  media_url_https: string;
  type: string;
  url: string;
  ext_media_availability: Extmediaavailability;
  features?: Features;
  sizes: Sizes;
  original_info: Originalinfo;
  additional_media_info?: Additionalmediainfo;
  video_info?: Videoinfo;
}
interface Videoinfo {
  aspect_ratio: number[];
  duration_millis: number;
  variants: Variant[];
}
interface Variant {
  content_type: string;
  url: string;
  bitrate?: number;
}
interface Additionalmediainfo {
  monetizable: boolean;
}
interface Originalinfo {
  height: number;
  width: number;
  focus_rects: Face[];
}
interface Sizes {
  large: Large2;
  medium: Large2;
  small: Large2;
  thumb: Large2;
}
interface Large2 {
  h: number;
  w: number;
  resize: string;
}
interface Features {
  large: Large;
  medium: Large;
  small: Large;
  orig: Large;
  all?: All;
}
interface All {
  tags: Tag[];
}
interface Tag {
  user_id: string;
  name: string;
  screen_name: string;
  type: string;
}
interface Large {
  faces: Face[];
}
interface Face {
  x: number;
  y: number;
  h: number;
  w: number;
}
interface Extmediaavailability {
  status: string;
}
interface Hashtag {
  indices: number[];
  text: string;
}
interface Views2 {
  count: string;
  state: string;
}
interface Editcontrol2 {
  edit_tweet_ids?: string[];
  editable_until_msecs?: string;
  is_edit_eligible?: boolean;
  edits_remaining?: string;
  initial_tweet_id?: string;
  edit_control_initial?: Editcontrol;
}
interface Core2 {
  user_results: Userresults2;
}
interface Userresults2 {
  result: Result2;
}
interface Result2 {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy2;
  professional?: Professional;
}
interface Legacy2 {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: string[];
  possibly_sensitive: boolean;
  profile_banner_url: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  verified: boolean;
  verified_type?: string;
  want_retweets: boolean;
  withheld_in_countries: any[];
  url?: string;
}
interface Views {
  count?: string;
  state: string;
}
interface Editcontrol {
  edit_tweet_ids: string[];
  editable_until_msecs: string;
  is_edit_eligible: boolean;
  edits_remaining: string;
}
interface Core {
  user_results: Userresults;
}
interface Userresults {
  result: Result;
}
interface Result {
  __typename: string;
  id: string;
  rest_id: string;
  affiliates_highlighted_label: Affiliateshighlightedlabel;
  has_graduated_access: boolean;
  is_blue_verified: boolean;
  profile_image_shape: string;
  legacy: Legacy;
  professional?: Professional;
}
interface Professional {
  rest_id: string;
  professional_type: string;
  category: Category[];
}
interface Category {
  id: number;
  name: string;
  icon_name: string;
}
interface Legacy {
  can_dm: boolean;
  can_media_tag: boolean;
  created_at: string;
  default_profile: boolean;
  default_profile_image: boolean;
  description: string;
  entities: Entities;
  fast_followers_count: number;
  favourites_count: number;
  followers_count: number;
  friends_count: number;
  has_custom_timelines: boolean;
  is_translator: boolean;
  listed_count: number;
  location: string;
  media_count: number;
  name: string;
  normal_followers_count: number;
  pinned_tweet_ids_str: string[];
  possibly_sensitive: boolean;
  profile_banner_url?: string;
  profile_image_url_https: string;
  profile_interstitial_type: string;
  screen_name: string;
  statuses_count: number;
  translator_type: string;
  url?: string;
  verified: boolean;
  want_retweets: boolean;
  withheld_in_countries: any[];
}
interface Entities {
  description: Description;
  url?: Url2;
}
interface Url2 {
  urls: Url[];
}
interface Description {
  urls: Url[];
}
interface Url {
  display_url: string;
  expanded_url: string;
  url: string;
  indices: number[];
}
interface Affiliateshighlightedlabel {}
