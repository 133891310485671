import { useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { GeneratedTweet } from "./useTweetStore";

export interface TweetRow {
  id: string;
  optionID?: string;
  tweet_text: string;
  internal_notes: string;
  locked: boolean;
  loading?: boolean;
  options: GeneratedTweet[];
  agent_uuid?: string;
}

export const useStackSpreadsheets = (initialRows: number = 1) => {
  const [rows, setRows] = useState<TweetRow[]>(
    Array.from({ length: initialRows }, (_, index) => ({
      id: uuidv4(),
      tweet_text: "",
      internal_notes: "",
      locked: false,
      options: [],
    }))
  );

  const addRow = useCallback(() => {
    setRows((prevRows) => [
      ...prevRows,
      {
        id: uuidv4(),
        tweet_text: "",
        internal_notes: "",
        locked: false,
        options: [],
      },
    ]);
  }, []);

  const updateRow = useCallback(
    (id: string, field: keyof TweetRow, value: string) => {
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, [field]: value } : row
        )
      );
    },
    []
  );

  const removeRow = useCallback((id: string) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  }, []);

  return {
    rows,
    addRow,
    updateRow,
    removeRow,
  };
};
